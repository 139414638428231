<script setup lang="ts">
import { ref } from "vue";
import AutocompleteAssets from "@/components/autocomplete/AutocompleteAssets.vue";
import ShipmentDropdown from "@/components/customerSupport/ShipmentDropdown.vue";

interface Props {
  customerId: string;
  labelClasses: string;
}

defineProps<Props>();

const selectedAsset = ref<string>();
</script>

<template>
  <AutocompleteAssets v-model="selectedAsset" :customer-id="customerId" :label-classes="labelClasses" />
  <ShipmentDropdown :customer-id="customerId" :label-classes="labelClasses" :selected-asset="selectedAsset" />
</template>
