<script setup lang="ts">
import { ref } from "vue";
const trigger = ref<HTMLSpanElement | null>();
const popover = ref<HTMLDivElement | null>();

type Props = {
  label: string;
  name: string;
};

defineProps<Props>();

function onToggle(e: ToggleEvent) {
  if (e.newState === "open") {
    if (trigger.value && popover.value) {
      const { right: triggerRight, bottom: triggerBottom } = trigger.value.getBoundingClientRect();
      const { width: popoverWidth } = popover.value.getBoundingClientRect();
      popover.value.style.top = triggerBottom + 4 + "px";
      popover.value.style.left = `${triggerRight - popoverWidth}px`;
    }
  }
}
</script>

<template>
  <div class="relative">
    <button
      ref="trigger"
      type="button"
      class="min-w-0 cursor-default rounded px-1 text-xs text-blue ring-1 ring-blue-500"
      :popovertarget="`${name}-popover`"
    >
      {{ label }}
    </button>
    <div
      :id="`${name}-popover`"
      ref="popover"
      class="absolute inset-[unset] rounded-md border bg-white shadow-lg"
      popover
      @toggle="onToggle"
    >
      <slot />
    </div>
  </div>
</template>
