import {
  BAPIWaybillActivityFeedDiff,
  BAPIWaybillDetailsResponse,
  BAPIWaybillEvent,
  BAPIWaybillNote,
  WaybillActivityFeedDiff,
  WaybillParty,
  WaybillEvent,
  WaybillNote,
  WaybillDetails,
  BAPIWaybillRouteSegment,
} from "@/bapi-client/types/waybills";
import { userFactory } from "../users";
import dayjs from "dayjs";
import { snakeToCamelCase } from "@/utils/text";
import { makeWaybillAsset } from "@/bapi-client/factories/assets/waybillAsset";
import { BAPIWaybillLocation, WaybillLocation } from "@/bapi-client/types/common/location";
import partyTypes from "@/bapi-client/types/waybills/partyTypes";
import { ProfilePicture } from "@/bapi-client/types/users";
import { BAPIAttachmentResponse, HandlerAttachmentResponse } from "@/bapi-client/types/common/attachment";

export function waybillActivityFeedFactory(data: BAPIWaybillActivityFeedDiff): WaybillActivityFeedDiff {
  const userData = {
    ...data.user,
    companies: [],
  };

  return {
    historyId: data.history_id,
    waybillId: data.waybill_id,
    customerId: data.customer_id,
    userId: data.user_id,
    createdByUserId: data.created_by_user_id,
    overrideField: data.override_field,
    overrideDisplayName: data.override_display_name,
    oldValue: data.old_value,
    newValue: data.new_value,
    createdTs: data.created_ts,
    updatedTs: data.updated_ts,
    operationTs: data.operation_ts,
    operation: data.operation,
    user: userFactory(userData),
  };
}

export function waybillEventFactory(data: BAPIWaybillEvent): WaybillEvent {
  return {
    id: data.id,
    sightingDate: data.sighting_date,
    sightingEventCode: data.sighting_event_code,
    sightingEventCodeText: data.sighting_event_code_text,
    reportingScac: data.reporting_scac,
    trainAlphaCode: data.train_alpha_code,
    trainId: data.train_id,
    location: waybillLocationFactory(data.location),
  };
}

export function waybillLocationFactory(data: BAPIWaybillLocation): WaybillLocation {
  return {
    id: data.id,
    displayName: data.display_name,
    station: data.station,
    timeZone: data.time_zone,
    latitude: data.latitude ?? null,
    longitude: data.longitude ?? null,
    splc: data.splc,
    scac: data.scac,
    state: data.state,
  };
}

export function waybillNoteFactory(data: BAPIWaybillNote): WaybillNote {
  const formattedAttachments: HandlerAttachmentResponse[] = data.attachments
    ? data.attachments.map((attachment: BAPIAttachmentResponse) => {
        return {
          id: attachment.id,
          userId: attachment.user_id,
          fileName: attachment.filename,
          fileSize: attachment.filesize,
          extension: attachment.extension,
          altText: attachment.alt_text,
          version: attachment.version,
          status: attachment.status,
          createdTimestamp: attachment.created_ts,
          updatedTimestamp: attachment.updated_ts ?? null,
          mimeType: attachment.mime_type,
          downloadURL: attachment.download_url,
          normalizedURL: attachment.normalized_url,
          thumbnailStatus: attachment.thumbnail_status,
          thumbnailURL: attachment.thumbnail_url,
        };
      })
    : [];

  // make separate factory in future
  let formattedProfilePicture: ProfilePicture | null = null;
  if (data.profile_picture) {
    formattedProfilePicture = {
      normalizedUrl: data.profile_picture.normalized_url,
      thumbnailUrl: data.profile_picture.thumbnail_url,
      rawUrl: data.profile_picture.raw_url,
      fileName: data.profile_picture.filename,
      mimeType: data.profile_picture.mime_type,
    };
  }

  return {
    id: data.id,
    waybillId: data.waybill_id,
    equipmentId: data.equipment_id,
    userId: data.user_id,
    note: data.note,
    text: data.text ?? "",
    timestamp: data.timestamp,
    customerId: data.customer_id,
    userName: data.user_name,
    userInitials: data.user_initials,
    companyName: data.company_name,
    attachments: formattedAttachments,
    profilePicture: formattedProfilePicture,
  };
}

export function waybillDetailsFactory(data: BAPIWaybillDetailsResponse): WaybillDetails {
  const formattedMeta: Record<string, string | null> = {};
  for (const key in data.metadata) {
    formattedMeta[snakeToCamelCase(key)] = data.metadata[key];
  }

  const formattedNotes = [];
  if (data.notes) {
    for (const note of data.notes) {
      if (note) {
        const formatted = waybillNoteFactory(note);
        if (formatted) {
          formattedNotes.push(formatted);
        }
      }
    }
  }

  let lastWaybillEvent: WaybillEvent | null = null;
  if (data.last_event) {
    lastWaybillEvent = waybillEventFactory(data.last_event);
  }

  const waybillEvents: WaybillEvent[] =
    data.events
      .filter((event: any) => !!event.location)
      .map((event: any): WaybillEvent => waybillEventFactory(event)) || [];

  return {
    id: data.waybill.id,
    asset: makeWaybillAsset(data.asset),
    endCanOverride: data.end_can_override,
    equipmentId: data.waybill.equipment_id,
    waybillNumber: data.waybill.waybill_number,
    waybillDate: dayjs(data.waybill.waybill_date).toDate(),
    created: dayjs(data.waybill.created_date).toDate(),
    billOfLadingDate: dayjs(data.waybill.bill_of_lading_date).toDate(),
    billingRoadMarkName: data.waybill.billing_road_mark_name,
    commodityCode: data.waybill.commodity_code,
    commodity: data.waybill.commodity_description,
    routeSegments: data.waybill.route_segments.map((segment: BAPIWaybillRouteSegment) => ({
      routingLegNumber: segment.routing_leg_number,
      scac: segment.scac,
    })),
    origin: data.waybill.origin ? waybillLocationFactory(data.waybill.origin) : null,
    destination: data.waybill.destination ? waybillLocationFactory(data.waybill.destination) : null,
    parties: data.waybill.parties.map(
      (party: any): WaybillParty => ({
        name: party.name,
        code: party.party_type,
        type: partyTypes[party.party_type] || "",
      }),
    ),
    events: waybillEvents,
    lastEvent: lastWaybillEvent,
    metadata: formattedMeta,
    notes: formattedNotes,
    waybillState: {
      destEndDate: data.waybill_state?.dest_end_date || "",
      lastEtaDate: data.waybill_state?.last_eta_date || "",
      lastReportingRailroadScac: data.waybill_state?.last_reporting_railroad_scac || "",
      startDate: data.waybill_state?.start_date || "",
      derampedDate: data.waybill_state?.deramped_date || "",
      destStartDate: data.waybill_state?.dest_start_date || "",
      motorCarrierArrivalDate: data.waybill_state?.motor_carrier_arrival_date || "",
      motorCarrierDepartureDate: data.waybill_state?.motor_carrier_departure_date || "",
      rampedDate: data.waybill_state?.ramped_date || "",
      destNotifyDate: data.waybill_state?.dest_notify_date || "",
      actualPlacementDate: data.waybill_state?.actual_placement_date || "",
    },
    editableFields: data.editable_fields,
  };
}

// export function AssetFactory(data: BAPIWaybillAssetResponse): WaybillAsset {

// }
