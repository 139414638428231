import { reactive, toRef, Ref } from "vue";
import { FormKitNode, register, getNode } from "@formkit/core";
import type { PatternInputDetails } from "@telegraphio/papi-client";

type StepData = {
  valid: Ref<boolean>;
  dirty: Ref<boolean>;
  blockingCount: number;
  errorCount: number;
  seen: boolean;
};

export default function useFormPlugins(variableInputs: Ref<Record<string, PatternInputDetails>>, isPattern: boolean) {
  const steps = reactive<Record<string, StepData>>({});

  const stepPlugin = (node: FormKitNode) => {
    const key = isPattern ? node.name : node.props.attrs.steplabel;
    const conditional = isPattern ? node.props.type === "group" : node.props.type !== "form";

    if (conditional) {
      // builds an object of the top-level groups
      steps[key] = steps[key] || { seen: false };

      node.on("created", () => {
        // use 'on created' to ensure context object is available
        if (node.context) {
          steps[key].valid = toRef(node.context.state, "valid");
          steps[key].dirty = toRef(node.context.state, "dirty");
        }
      });

      // NEW: Store or update the count of blocking validation messages.
      // FormKit emits the "count:blocking" event (with the count) each
      // time the count changes.
      node.on("count:blocking", ({ payload: count }: { payload: number }) => {
        steps[key].blockingCount = count;
      });

      // NEW: Store or update the count of backend error messages.
      node.on("count:errors", ({ payload: count }: { payload: number }) => {
        steps[key].errorCount = count;
      });

      // Stop plugin inheritance to descendant nodes
      return false;
    }
  };

  const pathPlugin = (node: FormKitNode) => {
    const excludeTypes = ["form", "group", "repeater"];

    const variablePaths = Object.values(variableInputs.value).map((input) => input.target);

    if (!excludeTypes.includes(node.props.type)) {
      let parent = node.parent;
      let path = node.name;
      let previousName = null;

      while (parent) {
        // Top level segments are wrapped by a group with the same name. So if the name matches
        // the previous name, we know we've reached the top.
        if (parent.name === previousName) {
          break;
        }

        let name = parent.name;

        if (Number.isInteger(parseInt(name))) {
          name = `[${name}]`;
        }

        path = `${name}.${path}`;
        previousName = parent.name;
        parent = parent.parent;
      }

      node.props.id = path;

      const isVariableCheckbox = node.name.endsWith("-checkbox");

      const truePath = isVariableCheckbox ? path.split("-")[0] : path;

      if (variablePaths.includes(truePath) && !isVariableCheckbox) {
        node.props.disabled = true;
      }

      if (isVariableCheckbox) {
        // We don't want the checkbox values in the output, so we intercept
        // the value and return undefined instead.
        node.hook.input((_, next) => {
          return next(undefined);
        });

        node.on("mounted", () => {
          const el = document.getElementById(path) as HTMLInputElement;
          if (variablePaths.includes(truePath)) {
            el.checked = true;
          }
        });
      }

      register(node);
    }

    return true;
  };

  const locationPlugin = (node: FormKitNode) => {
    const lookupNodes = ["origin-lookup", "destination-lookup", "disposition-lookup", "party-lookup"];

    if (node.props.type === "autocomplete" && lookupNodes.includes(node.name)) {
      node.on("created", () => {
        const index = node.props.id?.split(".")[1];

        let city = null;
        let state = null;
        let country = null;

        switch (node.name) {
          case "origin-lookup":
            city = getNode("origin_station_F9.city_name_02")?.value;
            state = getNode("origin_station_F9.state_or_province_code_03")?.value;
            country = getNode("origin_station_F9.country_code_04")?.value;
            break;
          case "destination-lookup":
            city = getNode("destination_station_D9.city_name_02")?.value;
            state = getNode("destination_station_D9.state_or_province_code_03")?.value;
            country = getNode("destination_station_D9.country_code_04")?.value;
            break;
          case "disposition-lookup":
            city = getNode(
              `empty_car_disposition_pended_destination_consignee_E1_loop.${index}.empty_car_disposition_pended_destination_city_E4.city_name_01`,
            )?.value;
            state = getNode(
              `empty_car_disposition_pended_destination_consignee_E1_loop.${index}.empty_car_disposition_pended_destination_city_E4.state_or_province_code_02`,
            )?.value;
            country = getNode(
              `empty_car_disposition_pended_destination_consignee_E1_loop.${index}.empty_car_disposition_pended_destination_city_E4.country_code_04`,
            )?.value;
            break;
          case "party-lookup":
            city = getNode(`party_identification_N1_loop.${index}.geographic_location_N4.city_name_01`)?.value;
            state = getNode(
              `party_identification_N1_loop.${index}.geographic_location_N4.state_or_province_code_02`,
            )?.value;
            country = getNode(`party_identification_N1_loop.${index}.geographic_location_N4.country_code_04`)?.value;
            break;
        }

        if (!city && !state && !country) {
          return;
        }

        const location = `${city || ""}, ${state || ""}, ${country || ""}`;

        node.input(location);
      });

      node.hook.commit((value, next) => {
        const index = node.props.id?.split(".")[1];

        if (!value) {
          return next(value);
        }

        const [city, state, country] = value.split(", ");

        switch (node.name) {
          case "origin-lookup":
            getNode("origin_station_F9.city_name_02")?.input(city);
            getNode("origin_station_F9.state_or_province_code_03")?.input(state);
            getNode("origin_station_F9.country_code_04")?.input(country);
            break;
          case "destination-lookup":
            getNode("destination_station_D9.city_name_02")?.input(city);
            getNode("destination_station_D9.state_or_province_code_03")?.input(state);
            getNode("destination_station_D9.country_code_04")?.input(country);
            break;
          case "disposition-lookup":
            getNode(
              `empty_car_disposition_pended_destination_consignee_E1_loop.${index}.empty_car_disposition_pended_destination_city_E4.city_name_01`,
            )?.input(city);
            getNode(
              `empty_car_disposition_pended_destination_consignee_E1_loop.${index}.empty_car_disposition_pended_destination_city_E4.state_or_province_code_02`,
            )?.input(state);
            getNode(
              `empty_car_disposition_pended_destination_consignee_E1_loop.${index}.empty_car_disposition_pended_destination_city_E4.country_code_04`,
            )?.input(country);
            break;
          case "party-lookup":
            getNode(`party_identification_N1_loop.${index}.geographic_location_N4.city_name_01`)?.input(city);
            getNode(`party_identification_N1_loop.${index}.geographic_location_N4.state_or_province_code_02`)?.input(
              state,
            );
            getNode(`party_identification_N1_loop.${index}.geographic_location_N4.country_code_04`)?.input(country);
            break;
        }

        next(undefined);
      });
    }
  };

  const commodityPlugin = (node: FormKitNode) => {
    if (node.name === "commodity-lookup") {
      node.on("commit", ({ payload }) => {
        if (!payload) {
          return;
        }

        const [code, description] = payload.split(" - ");

        const descriptionId = node.props.id?.replace("commodity-lookup", "lading_description_02");
        const codeId = node.props.id?.replace("commodity-lookup", "commodity_code_03");

        getNode(descriptionId || "")?.input(description);
        getNode(codeId || "")?.input(code);

        node.input(undefined);
      });
    }
  };

  return { steps, stepPlugin, pathPlugin, locationPlugin, commodityPlugin };
}
