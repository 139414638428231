<script setup lang="ts">
import * as Sentry from "@sentry/vue";
import { onBeforeMount, onErrorCaptured } from "vue";
import { useRouter, useRoute } from "vue-router";
import Rudderstack, { RUDDERSTACK_EVENTS } from "@/lib/rudderstack";
import useTrackerStore from "@/stores/trackers";
import useNotificationStore from "@/stores/notifications";
import AppNotifications from "@/components/AppNotifications.vue";
import TheSidebar from "@/components/TheSidebar.vue";
import PlatformAlert from "./components/notifications/PlatformAlert.vue";
import { ldInit } from "launchdarkly-vue-client-sdk";
import useFeatureStore from "./stores/features";

const [ldReady] = ldInit({
  clientSideID: import.meta.env.VITE_LAUNCH_DARKLY_CLIENT_ID,
});

useFeatureStore();

const rudderstack = new Rudderstack();
const trackers = useTrackerStore();
const router = useRouter();
const route = useRoute();
const notifier = useNotificationStore();

rudderstack.init();
rudderstack.registerRouter(router);
trackers.rudderstack = rudderstack;

async function reloadPage() {
  const response = await notifier.getLatestAppVersion();
  if (response.length) {
    trackers.logRudderstackEvent(RUDDERSTACK_EVENTS.APP_EVENT_UPDATE_BANNER_REFRESH, {
      current_version: response,
    });
  }
  const getIndex = await fetch(window.location.href, {
    headers: {
      "Cache-Control": "no-store",
      Pragma: "no-cache",
    },
  });
  if (getIndex.ok) {
    location.reload();
  }
}

onBeforeMount(async () => {
  await router.isReady();
  if (import.meta.env.VITE_VERSION_POLLING_INTERVAL) {
    setInterval(notifier.checkForNewerVersion, import.meta.env.VITE_VERSION_POLLING_INTERVAL);
  }
  navigator.serviceWorker.getRegistrations().then(function (registrations) {
    for (let registration of registrations) {
      registration.unregister();
    }
  });
});

onErrorCaptured((error, component) => {
  console.error("Uncaught error from child component: ", error);
  console.log(component);
  if (import.meta.env.PROD) {
    Sentry.captureException(error);
  }
  notifier.status = "fault";
  return false;
});
</script>

<template>
  <div class="overflow-visible bg-gray-100">
    <Transition
      enter-active-class="transition ease-out duration-100"
      enter-from-class="opacity-0"
      enter-to-class="opacity-100"
      leave-active-class="transition ease-out duration-100"
      leave-from-class="opacity-100"
      leave-to-class="opacity-0"
    >
      <div v-show="notifier.showUpdateBanner" class="fixed inset-x-0 top-0 z-50">
        <div class="bg-blue px-6 py-2.5 sm:px-3.5 sm:before:flex-1">
          <p class="text-center text-sm leading-6 text-white">
            <a class="underline hover:text-white-ice" href="#0" @click.prevent="reloadPage">
              <strong class="font-semibold">Update available! Click here to update to the latest version.</strong>
            </a>
          </p>
        </div>
      </div>
    </Transition>
    <div id="modal-container"></div>
    <main
      v-if="ldReady"
      id="app-content"
      class="relative h-screen overflow-hidden bg-gray-100 md:overflow-visible"
      :class="[route.path.startsWith('/login') || route.path.startsWith('/terminal') ? 'p-0' : 'xl:pl-16']"
    >
      <PlatformAlert v-if="!route.path.startsWith('/shipper') && !route.path.startsWith('/login')" />
      <TheSidebar v-if="!route.path.startsWith('/login') && !route.path.startsWith('/terminal')" />
      <router-view v-slot="{ Component }">
        <component :is="notifier.statusPage" v-if="notifier.status === 'fault'" />
        <component :is="Component" v-else />
      </router-view>
    </main>
    <AppNotifications />
  </div>
</template>
