<script setup lang="ts">
import { ref, computed } from "vue";
import { useRoute, useRouter } from "vue-router";
import AdminRefreshDataButton from "@/components/admin/AdminRefreshDataButton.vue";
import AnalyticsLinks from "@/components/app/AnalyticsLinks.vue";
import VisibilityLinks from "@/components/app/VisibilityLinks.vue";
import PlatformAlertSidebarLink from "@/components/notifications/PlatformAlertSidebarLink.vue";
import { SidebarMenu, SidebarMenuItem, SidebarMenuGroup } from "@/components/menus";
import { useUserStore } from "@/stores/user";
import useFeatureStore from "@/stores/features";

const route = useRoute();
const router = useRouter();
const userStore = useUserStore();
const featureStore = useFeatureStore();
const showSidebar = ref(false);
// @TODO: Remove from AppWrapper. This is meant to be a reusable component for scaffolding UI,
// but now it's responsible for which items belong in the navigation.

const hasAdvancedAnalytics = computed(() => {
  return userStore.company?.integrations?.tableau?.views?.length;
});

const telematicsLinks = computed(() => {
  return [
    {
      label: "Watchtower",
      icon: "light,tower-observation",
      to: router.resolve({ name: "watchtower", params: { companyId: userStore.companyId } }),
      current: route.path.includes("/telematics"),
    },
  ];
});

const reportLinks = computed(() => {
  const links = [];
  if (featureStore.features.hasReportBuilderModule) {
    links.push({
      label: "Report Builder",
      icon: "light,table",
      to: router.resolve({
        name: "reportBuilderDashboard",
        params: { companyId: userStore.companyId },
      }),
      current: route.path.includes("/report-builder"),
    });
  }
  if (featureStore.features.hasPipelineViewReportModule) {
    links.push({
      label: "Pipeline View",
      icon: "light,clipboard-list",
      to: router.resolve({ name: "pipelineReport", params: { companyId: userStore.companyId } }),
      current: route.name === "pipelineReport",
    });
  }
  if (featureStore.features.hasForecastingReportModule) {
    links.push({
      label: "Forecasting",
      icon: "light,crystal-ball",
      to: router.resolve({ name: "forecastReport", params: { companyId: userStore.companyId } }),
      current: route.name === "forecastReport",
    });
  }

  if (featureStore.features.hasNetworkMapModule) {
    links.push({
      label: "Network Map",
      icon: "light,map-location",
      to: router.resolve({ name: "networkMapReport", params: { companyId: userStore.companyId } }),
      current: route.name === "networkMapReport",
    });
  }
  return links;
});

const waybillingLinks = computed(() => {
  return [
    {
      label: "Booking Requests",
      icon: "light,file-invoice",
      to: router.resolve({ name: "waybillsList", params: { companyId: userStore.companyId } }),
      current: route.path.includes("/waybills"),
    },
    {
      label: "Patterns",
      icon: "light,layer-group",
      to: router.resolve({ name: "patternsList", params: { companyId: userStore.companyId } }),
      current: route.path.includes("/patterns"),
    },
  ];
});

const internalLinks = computed(() => {
  const links = [
    {
      label: "Users",
      icon: "light,users",
      to: router.resolve({ name: "adminUserList" }),
      current: route.path.includes("/admin/users"),
    },
    {
      label: "Customers",
      icon: "light,buildings",
      to: router.resolve({ name: "adminCustomerList" }),
      current: route.path.includes("/admin/customers"),
    },
  ];
  return links;
});
</script>

<template>
  <SidebarMenu
    v-if="userStore.companyId"
    v-slot="{ isOpen }"
    :sidebar-open="showSidebar"
    @close-sidebar="showSidebar = false"
  >
    <SidebarMenuItem
      v-if="featureStore.features.hasDashboardPage"
      :is-open="isOpen"
      :to="{ name: 'dashboard' }"
      class="uppercase tracking-widest"
      label="Dashboard"
      icon="solid,chart-tree-map"
      :current="route.name === 'dashboard'"
      @click="showSidebar = false"
    />
    <VisibilityLinks v-if="featureStore.features.hasVisibilityFeatures" :is-open="isOpen" />
    <SidebarMenuGroup
      v-if="featureStore.features.hasWatchtowerModule"
      :is-open="isOpen"
      label="Telematics"
      icon="solid,satellite-dish"
      :links="telematicsLinks"
      :current="route.path.includes('/telematics')"
      @close-menu="showSidebar = false"
    />
    <AnalyticsLinks v-if="hasAdvancedAnalytics" :is-open="isOpen" />
    <SidebarMenuGroup
      v-if="featureStore.features.hasReportingModule"
      :is-open="isOpen"
      label="Reporting"
      icon="solid,chart-pie-simple"
      :links="reportLinks"
      :current="route.path.includes('/reports')"
      @close-menu="showSidebar = false"
    />
    <SidebarMenuItem
      v-if="featureStore.features.hasPricingModule"
      :is-open="isOpen"
      :to="router.resolve({ name: 'pricingDashboard', params: { companyId: userStore.companyId } })"
      class="uppercase tracking-widest"
      label="Pricing"
      icon="solid,file-invoice-dollar"
      :current="route.path.includes('/pricing')"
      @click="showSidebar = false"
    />
    <SidebarMenuGroup
      v-if="featureStore.features.hasWaybillModule"
      :is-open="isOpen"
      label="Waybilling"
      icon="solid,train-track"
      :links="waybillingLinks"
      :current="route.path.includes('/waybilling')"
      @close-menu="showSidebar = false"
    />
    <SidebarMenuItem
      v-if="featureStore.features.hasCustomerSupportModule"
      :to="{ name: 'customerSupport', params: { customerId: userStore.companyId } }"
      :is-open="isOpen"
      label="Support"
      icon="solid,headset"
      class="uppercase tracking-widest"
      :current="route.path.includes('/customer-support')"
      @click="showSidebar = false"
    />
    <SidebarMenuGroup
      v-if="featureStore.features.hasInternalToolsModule"
      :is-open="isOpen"
      label="Internal Tools"
      icon="solid,tools"
      :links="internalLinks"
      :current="route.path.includes('/admin')"
    >
      <AdminRefreshDataButton />
    </SidebarMenuGroup>
    <PlatformAlertSidebarLink :is-open="isOpen" />
  </SidebarMenu>
  <div class="flex w-full flex-1 flex-col overflow-x-hidden xl:pl-16">
    <div class="sticky top-0 z-10 bg-gray-100 pl-1 pt-1 sm:pl-3 sm:pt-3 xl:hidden">
      <button
        type="button"
        class="-ml-0.5 -mt-0.5 inline-flex h-12 w-12 items-center justify-center rounded-md text-gray-500 hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-blue-500"
        @click="showSidebar = true"
      >
        <span class="sr-only">Open sidebar</span>
        <i class="fa-solid fa-bars" aria-hidden="true" />
      </button>
    </div>
  </div>
</template>
