<script setup lang="ts">
import { computed } from "vue";
import SidebarUserMenu from "./SidebarUserMenu.vue";
import SidebarCustomerMenu from "./SidebarCustomerMenu.vue";
import AppAssetSearch from "@/components/app/AppAssetSearch.vue";
import { Dialog, DialogPanel, TransitionChild, TransitionRoot } from "@headlessui/vue";
import useFeatureStore from "@/stores/features";
import InTeleDeskForm from "@/components/InTeleDeskForm.vue";

interface Props {
  sidebarOpen: boolean;
}

const featureStore = useFeatureStore();

const bgClasses = computed(() => {
  return import.meta.env.PROD
    ? "border-blue-midnight-600 bg-gradient-to-r from-blue-midnight-400 to-blue-midnight-300"
    : "border-purple-950 bg-purple-950";
});

defineProps<Props>();
defineEmits<{ (event: "close-sidebar"): void }>();
</script>
<template>
  <TransitionRoot as="template" :show="sidebarOpen">
    <Dialog as="div" class="relative z-40 xl:hidden" @close="$emit('close-sidebar')">
      <TransitionChild
        as="template"
        enter="transition-opacity ease-linear duration-300"
        enter-from="opacity-0"
        enter-to="opacity-100"
        leave="transition-opacity ease-linear duration-300"
        leave-from="opacity-100"
        leave-to="opacity-0"
      >
        <div class="fixed inset-0 bg-gray-600 bg-opacity-75" />
      </TransitionChild>

      <div class="fixed inset-0 z-40 flex">
        <TransitionChild
          as="template"
          enter="transition ease-in-out duration-300 transform"
          enter-from="-translate-x-full"
          enter-to="translate-x-0"
          leave="transition ease-in-out duration-300 transform"
          leave-from="translate-x-0"
          leave-to="-translate-x-full"
        >
          <DialogPanel class="relative flex w-full max-w-xs flex-1 flex-col" :class="bgClasses">
            <TransitionChild
              as="template"
              enter="ease-in-out duration-300"
              enter-from="opacity-0"
              enter-to="opacity-100"
              leave="ease-in-out duration-300"
              leave-from="opacity-100"
              leave-to="opacity-0"
            >
              <div class="absolute right-0 top-0 -mr-12 pt-2">
                <button
                  type="button"
                  class="ml-1 flex h-10 w-10 items-center justify-center rounded-full align-middle focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                  @click="$emit('close-sidebar')"
                >
                  <span class="sr-only">Close sidebar</span>
                  <i class="fa-solid fa-xmark text-white" aria-hidden="true" />
                </button>
              </div>
            </TransitionChild>
            <div class="h-0 flex-1 overflow-y-auto pb-4 pt-5">
              <div class="flex flex-shrink-0 items-center px-4">
                <img class="h-12 w-auto" src="/src/assets/tg_logo_outline_cropped.png" alt="Your Company" />
                <SidebarCustomerMenu />
              </div>
              <nav class="mt-5 space-y-1 px-2">
                <slot name="navigation" :is-open="true" />
              </nav>
            </div>
            <InTeleDeskForm v-if="featureStore.features.hasInteledeskForm && sidebarOpen" />
            <AppAssetSearch v-if="featureStore.features.hasVisibilityFeatures" :show-search="sidebarOpen" />
            <SidebarUserMenu :sidebar-open="true" />
          </DialogPanel>
        </TransitionChild>
        <div class="w-14 flex-shrink-0" aria-hidden="true">
          <!-- Force sidebar to shrink to fit close icon -->
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>
