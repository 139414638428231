import { EquipmentStatus } from "@/bapi-client/types/assets/equipmentStatus";
import { ETAResponse, ETA, makeETA } from "../etas";

// This is a representation of ONLY the shared keys between assets returned on the
// visibility dashboard, dashboard, and pipeline report. Don't use this type directly in
// your code, there's probably a more suitable one for the page you're developing.
export interface AssetModel {
  equipmentId: string;
  waybillId: string;
  status: EquipmentStatus;
  statusCode: number;
  loadEmptyStatus: "L" | "E";
  billOfLadingNumber: string | null;
  intermodalShipment: boolean;
  sealNumber: string | null;
  imdlPickupNumber: string | null;
  poNumber: string | null;
  imdlBookingNumber: string | null;
  equipmentLength: number | null;
  equipmentWeight: number | null;
  equipmentHeight: number | null;
  tareWeight: number | null;
  commodity: string;
  commodityDescription: string;
  currentLocation: string;
  lastEventText: string;
  lastSightingDate: string;
  originLocation: string;
  destinationLocation: string;
  daysSinceLastSighting: number | string;
  exceptions: string[];
  railETA: ETA | null;
  teleETA: ETA | null;
  teleETADate: string;
  railETADate: string;
}

// Just boilerplate to help create appropriate types for JSON responses
export interface AssetModelResponse {
  equipment_id: string;
  waybill_id: string;
  status: string;
  status_code: number;
  load_empty_status: string;
  bill_of_lading_number: string | null;
  intermodal_shipment: boolean;
  seal_number: string | null;
  imdl_pickup_number: string | null;
  po_number: string | null;
  imdl_booking_number: string | null;
  equipment_length: number | null;
  equipment_weight: number | null;
  equipment_height: number | null;
  tare_weight: number | null;
  commodity: string;
  commodity_description: string;
  current_location: string;
  last_event_text: string;
  last_sighting_date: string;
  origin_location: string;
  destination_location: string;
  days_since_last_sighting: number | string;
  exceptions: string[];
  rail_eta: ETAResponse;
  tele_eta: ETAResponse;
}

// This function assumes that T contains potentially _more than_ the properties listed
// in AssetModelResponse, but never _less than_ those properties.
// Returns a shape that satisfies AssetModel, so your downstream handler can add the specific properties
// needed for the page you're building.
export function makeAsset<T extends AssetModelResponse>(response: T): AssetModel {
  return {
    equipmentId: response.equipment_id,
    waybillId: response.waybill_id,
    status: response.status as EquipmentStatus,
    statusCode: response.status_code,
    loadEmptyStatus: response.load_empty_status as "L" | "E",
    billOfLadingNumber: response.bill_of_lading_number,
    intermodalShipment: response.intermodal_shipment,
    sealNumber: response.seal_number,
    imdlPickupNumber: response.imdl_pickup_number,
    poNumber: response.po_number,
    imdlBookingNumber: response.imdl_booking_number,
    equipmentHeight: response.equipment_height,
    equipmentLength: response.equipment_length,
    equipmentWeight: response.equipment_weight,
    tareWeight: response.tare_weight,
    commodity: response.commodity,
    commodityDescription: response.commodity_description,
    currentLocation: response.current_location,
    lastEventText: response.last_event_text,
    lastSightingDate: response.last_sighting_date,
    originLocation: response.origin_location,
    destinationLocation: response.destination_location,
    daysSinceLastSighting: response.days_since_last_sighting !== "" ? Number(response.days_since_last_sighting) : "",
    exceptions: response.exceptions,
    railETA: makeETA(response.rail_eta),
    teleETA: makeETA(response.tele_eta),
    get teleETADate() {
      return this.teleETA?.date ?? "";
    },
    get railETADate() {
      return this.railETA?.date ?? "";
    },
  };
}

export interface APIAssetHistory {
  waybill_id: string;
  load_empty_status: "L" | "E";
  origin_location: string;
  destination_location: string;
  waybill_date: string;
}

export interface APIAssetHistoryResponse {
  success: boolean;
  total_record_count: number;
  number_of_pages: number;
  current_page: number;
  current_page_size: number;
  page_size: number;
  data: APIAssetHistory[];
  min_waybill_date: string;
  max_waybill_date: string;
}

export interface AssetHistory {
  waybillId: string;
  loadEmptyStatus: "L" | "E";
  origin: string;
  destination: string;
  waybillDate: string;
}
