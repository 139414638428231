import dayjs from "dayjs";
import { VisibilityAsset } from "@/models/assets/visibilityAsset";
import { snakeToCamelCase } from "./text";

export function deSpacinator(str: number): string;
export function deSpacinator(str: string): string;
export function deSpacinator(str: string[]): string[];

export function deSpacinator(str: number | string | string[]): string | string[] {
  if (typeof str === "number") {
    return ("" + str).toUpperCase().replace(/\s+/g, "");
  }
  if (typeof str === "string") {
    return str.toUpperCase().replace(/\s+/g, "");
  }
  return str.map((s: string): string => deSpacinator(s));
}

// Declared here to prevent redeclaration on every call;
const comparators: { [key: string]: any } = {
  "equal to": (a: any, b: any) => ("" + a).toUpperCase() === ("" + b).toUpperCase(),
  "not equal to": (a: any, b: any) => a !== b,
  "greater than": (a: string | number, b: string | number) => a > b,
  "less than": (a: string | number, b: string | number) => a < b,
  has: (a: string) => !!a,
  "does not have": (a: string) => !a,
  includes: (a: number | string | string[], b: number | string | string[]) => {
    // Upcase and remove spaces from value and target for better comparison
    // The property on the asset is an array, see if the values/value of b is in that array
    if (Array.isArray(a)) {
      if (Array.isArray(b)) {
        // These are both arrays. Is anything from b in a?
        return deSpacinator(b).some((str) => deSpacinator(a).includes(str));
      }
      // Looks like a is an array, but b is just a number or string. Does a include b?
      return deSpacinator(a).includes(deSpacinator("" + b));
    }
    // a is not an array, but a string or number. Is b an array?
    if (Array.isArray(b) && (typeof a === "string" || typeof a === "number")) {
      return deSpacinator(b).includes(deSpacinator("" + a));
    }
    // They are both strings or numbers, so look for overlap in values.
    if ((typeof b === "string" || typeof b === "number") && (typeof a === "string" || typeof a === "number")) {
      return deSpacinator("" + a).includes(deSpacinator("" + b));
    }
  },
  before: (a: string, b: string) => dayjs(a).isBefore(dayjs(b)),
  after: (a: string, b: string) => dayjs(a).isAfter(dayjs(b)),
};

export function advancedFilterAssets(
  assets: any[],
  column: string,
  comparator: string,
  value: string | number | string[],
) {
  // Handle "falsyish" values. Assume a falsyish value means a default or unintended value was passed.
  if (Array.isArray(value) && !value.length) {
    return assets;
  }

  if (value === "") {
    return assets;
  }

  if (value === 0) {
    return assets;
  }
  return assets.filter((asset): boolean =>
    comparators[comparator](asset[snakeToCamelCase(column) as keyof VisibilityAsset], value),
  );
}
