<script setup lang="ts">
import { useRouteQuery } from "@vueuse/router";
import CustomerSupportHeader from "@/components/customerSupport/CustomerSupportHeader.vue";
import TicketTable from "@/components/customerSupport/TicketTable.vue";
import { watch } from "vue";
import { useDebounceFn } from "@vueuse/core";

defineProps<{ customerId: string }>();

const pageNumber = useRouteQuery("page_number", "1", { transform: Number });
const pageSize = useRouteQuery("page_size", "25", { transform: Number });
const sortColumn = useRouteQuery("sort", "priority");
const sortDirection = useRouteQuery("direction", "ASC");

const debouncedFetch = useDebounceFn(
  () => console.log(`Fetching page ${pageNumber.value} with size ${pageSize.value}`),
  600,
);

watch([pageNumber, pageSize, sortColumn, sortDirection], () => {
  debouncedFetch();
});
</script>

<template>
  <section class="p-6 pb-0">
    <CustomerSupportHeader :customer-id="customerId" />
    <TicketTable :customer-id="customerId" class="my-6 rounded-md border" />
    <router-view />
  </section>
</template>
