const partyTypes: Record<string, string> = {
  "11": "Rule 11 Party",
  AP: "Account Of (Origin Party)",
  AQ: "Account Of (Destination Party)",
  BN: "Beneficial Owner",
  C1: "Care of Party",
  CB: "Customs Broker",
  CN: "Consignee",
  FW: "Forwarder",
  IM: "Importer",
  N1: "Notify Party",
  PF: "Payor of Freight",
  PU: "Party at Pickup Location",
  PV: "Party Performing Certification",
  SF: "Shipped By",
  SH: "Shipper",
  UC: "Ultimate Consignee",
  XQ: "Canadian Customs Broker",
  XR: "Mexican Customs Broker",
  XU: "US Customs Broker",
  ZS: "Monitoring Party",
};

export default partyTypes;
