<script setup lang="ts">
import { OnClickOutside } from "@vueuse/components";
import { Disclosure, DisclosurePanel, DisclosureButton } from "@headlessui/vue";
import SidebarMenuItem from "./SidebarMenuItem.vue";
import { RouteLocation } from "vue-router";

interface Props {
  label: string;
  icon: string;
  current: boolean;
  links: {
    to: RouteLocation;
    label: string;
    icon: string;
    current: boolean;
  }[];
  isOpen: boolean;
}

const props = defineProps<Props>();
const emit = defineEmits<{ (event: "close-menu"): void; (event: "open-menu"): void }>();

function openMenu() {
  if (!props.isOpen) {
    emit("open-menu");
  }
}
</script>

<template>
  <Disclosure ref="group" v-slot="{ open, close }" class="relative" as="div">
    <OnClickOutside @trigger="close">
      <div :class="[open && isOpen ? 'pb-1' : '']">
        <DisclosureButton
          v-if="isOpen"
          as="i"
          class="fa-light fa-angle-down absolute right-6 top-2 z-10 cursor-pointer text-xl"
          :class="[
            open ? 'rotate-180 ' : 'rotate-0',
            'transition duration-100 ease-out',
            current ? 'text-blue-sky-600' : 'text-white',
          ]"
        ></DisclosureButton>
        <DisclosureButton
          :is-open="isOpen"
          :as="SidebarMenuItem"
          :label="label"
          :icon="icon"
          :current="current"
          class="relative cursor-pointer whitespace-nowrap uppercase tracking-widest"
          :class="[isOpen && open ? 'bg-blue-500 bg-opacity-75' : '']"
          @click="openMenu"
        />
        <transition
          enter-active-class="transition duration-100 ease-out"
          enter-from-class="transform scale-95 opacity-0"
          enter-to-class="transform scale-100 opacity-100"
          leave-active-class="transition duration-75 ease-out"
          leave-from-class="transform scale-100 opacity-100"
          leave-to-class="transform scale-95 opacity-0"
        >
          <div v-show="open && isOpen">
            <DisclosurePanel :static="true">
              <div class="flex-1 space-y-1 px-2 pl-4 pt-1">
                <SidebarMenuItem
                  v-for="link in links"
                  :id="link.label"
                  :key="link.label"
                  data-testid="nav-link"
                  :is-open="isOpen"
                  :label="link.label"
                  :to="link.to"
                  :current="link.current"
                  :icon="link.icon"
                  @click="close"
                />
              </div>
              <slot />
            </DisclosurePanel>
          </div>
        </transition>
      </div>
    </OnClickOutside>
  </Disclosure>
</template>
