import { BAPIEtaResponse, Eta } from "@/bapi-client/types/assets/eta";
import { HandlerAttachmentResponse, BAPIAttachmentResponse } from "@/bapi-client/types/common/attachment";

export function makeETA(response: BAPIEtaResponse | null): Eta | null {
  if (!response) {
    return null;
  }

  return {
    code: response.code,
    date: response.date,
    delayed: response.delayed,
    sourceScac: response.source_scac,
    updatedDaysAgo: response.updated_days_ago,
    updatedUTC: response.updated_utc,
  };
}

export function makeAttachment(response: BAPIAttachmentResponse): HandlerAttachmentResponse {
  return {
    id: response.id,
    userId: response.user_id,
    fileName: response.filename,
    fileSize: response.filesize,
    extension: response.extension,
    altText: response.alt_text,
    version: response.version,
    status: response.status,
    createdTimestamp: response.created_ts,
    updatedTimestamp: response.updated_ts,
    mimeType: response.mime_type,
    downloadURL: response.download_url,
    normalizedURL: response.normalized_url,
    thumbnailStatus: response.thumbnail_status,
    thumbnailURL: response.thumbnail_url,
  };
}
