import type { FormKitNode } from "@formkit/core";

/**
 * @privateRemarks
 * This file was generated by the FormKit CLI and should not be manually
 * edited unless you’d like to "eject" from the CLI’s ability to update it.
 *
 * @checksum - 95b55bb8bd06dc93e46026193c3bb1b0f39978113b775a9163bcc9f15e44e013
 * @variables - undefined
 * @theme - telegraph
 **/

/**
 * This is the theme function itself, it should be imported and used as the
 * config.rootClasses function. For example:
 *
 * ```js
 * import { theme } from './formkit.theme'
 * import { defineFormKitConfig } from '@formkit/vue'
 *
 * export default defineFormKitConfig({
 *   config: {
 *     rootClasses: theme
 *   }
 * })
 * ```
 **/
export function rootClasses(
  sectionName: string,
  node: FormKitNode,
): Record<string, boolean> {
  const key = `${node.props.type}__${sectionName}`;
  const semanticKey = `formkit-${sectionName}`;
  const familyKey = node.props.family
    ? `family:${node.props.family}__${sectionName}`
    : "";
  const memoKey = `${key}__${familyKey}`;
  if (!(memoKey in classes)) {
    const sectionClasses = classes[key] ?? globals[sectionName] ?? {};
    sectionClasses[semanticKey] = true;
    if (familyKey in classes) {
      classes[memoKey] = { ...classes[familyKey], ...sectionClasses };
    } else {
      classes[memoKey] = sectionClasses;
    }
  }
  return classes[memoKey] ?? { [semanticKey]: true };
}

/**
 * These classes have already been merged with globals using tailwind-merge
 * and are ready to be used directly in the theme.
 **/
const classes: Record<string, Record<string, boolean>> = {
  "family:button__outer": {
    group: true,
    "flex-grow": true,
    "min-w-0": true,
    "text-base": true,
    "data-[disabled]:select-none": true,
    "data-[disabled]:opacity-50": true,
    "data-[disabled]:pointer-events-none": true,
  },
  "family:button__wrapper": {
    "": true,
  },
  "family:button__label": {
    block: true,
    "text-black-500": true,
    "text-sm": true,
    "mb-1.5": true,
    "font-medium": true,
    "dark:text-black-500": true,
  },
  "family:button__prefixIcon": {
    flex: true,
    "shrink-0": true,
    "items-center": true,
    "-ml-1.5": true,
    "mr-1.5": true,
    "text-base": true,
    "h-[1em]": true,
    "w-[1em]": true,
    "[&>svg]:w-full": true,
    "text-black-500": true,
    "dark:text-black-500": true,
  },
  "family:button__suffixIcon": {
    flex: true,
    "items-center": true,
    "text-base": true,
    "h-[1em]": true,
    "w-[1em]": true,
    "shrink-0": true,
    "[&>svg]:w-full": true,
    "text-black-500": true,
    "dark:text-black-500": true,
    "ml-3": true,
    "-mr-9": true,
  },
  "family:button__input": {
    "text-sm": true,
    "appearance-none": true,
    "[color-scheme:light]": true,
    "dark:[color-scheme:dark]": true,
    "placeholder:text-gray-400": true,
    "placeholder:whitespace-nowrap": true,
    "py-1.5": true,
    flex: true,
    "items-center": true,
    "rounded-md": true,
    "h-8": true,
    "outline-none": true,
    "text-black-500": true,
    "group-data-[disabled]:!cursor-not-allowed": true,
    "group-data-[prefix-icon]:pl-12": true,
    "group-data-[suffix-icon]:pr-12": true,
    "dark:border-black-500": true,
    "dark:text-black-500": true,
  },
  "family:button__help": {
    "text-black-500": true,
    "text-sm": true,
    "dark:text-black-500": true,
  },
  "family:button__messages": {
    "": true,
  },
  "family:button__message": {
    "text-red-500": true,
    "mb-1.5": true,
    "text-sm": true,
    "dark:text-red-400": true,
  },
  "family:box__outer": {
    group: true,
    "flex-grow": true,
    "min-w-0": true,
    "text-base": true,
    "data-[disabled]:select-none": true,
    "data-[disabled]:opacity-50": true,
    "data-[disabled]:pointer-events-none": true,
  },
  "family:box__wrapper": {
    "inline-flex": true,
    "items-center": true,
    "mb-1": true,
    "group-data-[multiple]:mb-0": true,
  },
  "family:box__fieldset": {
    "": true,
  },
  "family:box__legend": {
    block: true,
    "text-black-500": true,
    "text-sm": true,
    "dark:text-black-500": true,
    "mb-3": true,
  },
  "family:box__help": {
    "text-black-500": true,
    "text-sm": true,
    "dark:text-black-500": true,
    "mb-1.5": true,
    "group-data-[multiple]:mb-3": true,
    "group-data-[multiple]:-mt-1.5": true,
  },
  "family:box__inner": {
    "": true,
  },
  "family:box__input": {
    "text-sm": true,
    "appearance-none": true,
    "outline-none": true,
    "[color-scheme:light]": true,
    "dark:[color-scheme:dark]": true,
    "placeholder:text-gray-400": true,
    "placeholder:whitespace-nowrap": true,
    "py-1.5": true,
    peer: true,
    absolute: true,
    "h-0": true,
    "w-0": true,
    "overflow-hidden": true,
    "opacity-0": true,
  },
  "family:box__decorator": {
    relative: true,
    block: true,
    "select-none": true,
    "w-[1em]": true,
    "aspect-[1/1]": true,
    border: true,
    "border-black-500": true,
    "mr-1.5": true,
    "text-transparent": true,
    "peer-checked:text-blue-500": true,
    "peer-disabled:cursor-not-allowed": true,
    "peer-focus:ring-2": true,
    "dark:border-black-500": true,
    "dark:peer-checked:text-blue-500": true,
  },
  "family:box__decoratorIcon": {
    absolute: true,
    "left-1/2": true,
    "top-1/2": true,
    flex: true,
    "h-full": true,
    "w-full": true,
    "-translate-x-1/2": true,
    "-translate-y-1/2": true,
  },
  "family:box__options": {
    "": true,
  },
  "family:box__option": {
    "mb-1.5": true,
    "last:mb-0": true,
    "data-[disabled]:opacity-50": true,
    "group-data-[disabled]:data-[disabled]:opacity-100": true,
  },
  "family:box__label": {
    block: true,
    "text-black-500": true,
    "text-sm": true,
    "mb-1.5": true,
    "font-medium": true,
    "dark:text-black-500": true,
    "!mb-0": true,
  },
  "family:box__optionHelp": {
    "text-black-600": true,
    "text-xs": true,
    "-mt-1": true,
    "mb-1.5": true,
    "dark:text-black-400": true,
  },
  "family:box__messages": {
    "": true,
  },
  "family:box__message": {
    "text-red-500": true,
    "mb-1.5": true,
    "text-sm": true,
    "dark:text-red-400": true,
  },
  "family:text__outer": {
    group: true,
    "flex-grow": true,
    "min-w-0": true,
    "text-base": true,
    "data-[disabled]:select-none": true,
    "data-[disabled]:opacity-50": true,
    "data-[disabled]:pointer-events-none": true,
  },
  "family:text__wrapper": {
    flex: true,
    "flex-col": true,
    "items-start": true,
    "justify-start": true,
    "last:mb-0": true,
  },
  "family:text__label": {
    block: true,
    "text-black-500": true,
    "text-sm": true,
    "mb-1.5": true,
    "font-medium": true,
    "dark:text-black-500": true,
  },
  "family:text__prefixIcon": {
    flex: true,
    "shrink-0": true,
    "items-center": true,
    "-ml-1.5": true,
    "mr-1.5": true,
    "text-base": true,
    "h-[1em]": true,
    "w-[1em]": true,
    "[&>svg]:w-full": true,
    "text-black-600": true,
    "dark:text-black-400": true,
  },
  "family:text__suffixIcon": {
    flex: true,
    "items-center": true,
    "-mr-1.5": true,
    "ml-1.5": true,
    "text-base": true,
    "h-[1em]": true,
    "w-[1em]": true,
    "shrink-0": true,
    "[&>svg]:w-full": true,
    "text-black-600": true,
    "dark:text-black-400": true,
  },
  "family:text__inner": {
    "w-full": true,
    "rounded-md": true,
    "border-black-500": true,
    "px-3": true,
    "py-1.5": true,
    "h-8": true,
    flex: true,
    "items-center": true,
    "ring-1": true,
    "ring-gray-300": true,
    "ring-inset": true,
    "focus-within:ring-2": true,
    "focus-within:ring-blue-500": true,
    "group-data-[disabled]:!cursor-not-allowed": true,
    "dark:border-black-500": true,
    "bg-white": true,
  },
  "family:text__input": {
    "appearance-none": true,
    "outline-none": true,
    "[color-scheme:light]": true,
    "dark:[color-scheme:dark]": true,
    "placeholder:text-gray-400": true,
    "placeholder:whitespace-nowrap": true,
    "py-1.5": true,
    "text-sm": true,
    "leading-none": true,
    "text-black-500": true,
    "min-h-[1.5em]": true,
    "bg-transparent": true,
    grow: true,
    "dark:text-black-500": true,
    "!py-0": true,
  },
  "family:text__help": {
    "text-black-500": true,
    "text-sm": true,
    "dark:text-black-500": true,
  },
  "family:text__messages": {
    "": true,
  },
  "family:text__message": {
    "text-red-500": true,
    "mb-1.5": true,
    "text-sm": true,
    "dark:text-red-400": true,
  },
  "family:dropdown__outer": {
    group: true,
    "flex-grow": true,
    "min-w-0": true,
    "text-base": true,
    "data-[disabled]:select-none": true,
    "data-[disabled]:opacity-50": true,
    "data-[disabled]:pointer-events-none": true,
  },
  "family:dropdown__wrapper": {
    "": true,
  },
  "family:dropdown__label": {
    block: true,
    "text-black-500": true,
    "text-sm": true,
    "mb-1.5": true,
    "font-medium": true,
    "dark:text-black-500": true,
  },
  "family:dropdown__inner": {
    relative: true,
    flex: true,
    "items-center": true,
    "rounded-md": true,
    "h-8": true,
    "ring-1": true,
    "ring-inset": true,
    "ring-gray-300": true,
    "focus-within:ring-2": true,
    "focus-within:ring-blue-500": true,
    "group-data-[is-multiline]:!rounded-md": true,
    "dark:border-black-500": true,
  },
  "family:dropdown__prefixIcon": {
    flex: true,
    "shrink-0": true,
    "items-center": true,
    "-ml-1.5": true,
    "mr-1.5": true,
    "text-base": true,
    "h-[1em]": true,
    "w-[1em]": true,
    "[&>svg]:w-full": true,
    "!ml-3": true,
    "!mr-0": true,
    "text-black-500": true,
    "dark:text-black-500": true,
  },
  "family:dropdown__suffixIcon": {
    flex: true,
    "items-center": true,
    "text-base": true,
    "h-[1em]": true,
    "w-[1em]": true,
    "shrink-0": true,
    "[&>svg]:w-full": true,
    "ml-3": true,
    "-mr-1.5": true,
    "text-black-500": true,
    "dark:text-black-500": true,
  },
  "family:dropdown__input": {
    "appearance-none": true,
    "outline-none": true,
    "[color-scheme:light]": true,
    "dark:[color-scheme:dark]": true,
    "placeholder:text-gray-400": true,
    "placeholder:whitespace-nowrap": true,
    grow: true,
    "rounded-md": true,
    "px-3": true,
    "py-1.5": true,
    "mr-3": true,
    "text-sm": true,
    "font-semibold": true,
    "text-black-500": true,
    "text-ellipsis": true,
    "min-w-0": true,
    "bg-transparent": true,
    "group-data-[prefix-icon]:!pl-0": true,
    "group-data-[suffix-icon]:!pr-0": true,
    "dark:text-black-500": true,
  },
  "family:dropdown__selectionWrapper": {
    grow: true,
    flex: true,
    "items-center": true,
    "border-none": true,
    "bg-transparent": true,
    "text-black-500": true,
    "dark:text-black-500": true,
  },
  "family:dropdown__selection": {
    "text-sm": true,
    "text-black-500": true,
    "dark:text-black-500": true,
  },
  "family:dropdown__tagsWrapper": {
    "w-full": true,
  },
  "family:dropdown__tags": {
    "inline-flex": true,
    "flex-wrap": true,
    "items-center": true,
    "w-full": true,
    "-mb-1.5": true,
    "empty:mb-0": true,
  },
  "family:dropdown__tagWrapper": {
    "group/tag": true,
    "mr-1.5": true,
    "mb-1.5": true,
    "outline-none": true,
    "data-[active-selection=true]:ring-2": true,
  },
  "family:dropdown__tag": {
    relative: true,
    flex: true,
    "items-center": true,
    "text-sm": true,
    "pl-3": true,
    "pr-[1.5em]": true,
    "py-3": true,
    "h-[1.66em]": true,
    "bg-blue-900": true,
    "rounded-md": true,
    "text-white": true,
    "dark:bg-blue-100": true,
    "dark:text-black": true,
  },
  "family:dropdown__tagLabel": {
    "": true,
  },
  "family:dropdown__controlLabel": {
    absolute: true,
    "opacity-0": true,
    "pointer-events-none": true,
    "text-[0px]": true,
  },
  "family:dropdown__listboxButton": {
    "w-[2.5em]": true,
    "self-stretch": true,
    "text-sm": true,
    flex: true,
    "items-center": true,
    "text-black-500": true,
    "z-10": true,
    "dark:text-black-500": true,
  },
  "family:dropdown__removeSelection": {
    "w-[2.5em]": true,
    "self-stretch": true,
    "text-base": true,
    flex: true,
    "bg-transparent": true,
    "items-center": true,
    "text-black-500": true,
    "z-10": true,
    "dark:text-black-500": true,
    "group-[]/tag:absolute": true,
    "group-[]/tag:top-1/2": true,
    "group-[]/tag:right-[0.5em]": true,
    "group-[]/tag:-translate-y-1/2": true,
    "group-[]/tag:!w-[0.5em]": true,
    "group-[]/tag:aspect-[1/1]": true,
    "group-[]/tag:!text-inherit": true,
  },
  "family:dropdown__selectIcon": {
    "text-base": true,
    "inline-flex": true,
    "justify-center": true,
    "w-[2.5em]": true,
    relative: true,
    "my-auto": true,
    "[&>svg]:w-[1em]": true,
    "[&>svg]:mx-auto": true,
  },
  "family:dropdown__closeIcon": {
    "text-base": true,
    "w-[0.75em]": true,
    relative: true,
    "m-auto": true,
  },
  "family:dropdown__loaderIcon": {
    "animate-spin": true,
    flex: true,
    "items-center": true,
    "my-auto": true,
    "text-base": true,
    "text-black-500": true,
    "h-[1em]": true,
    "dark:text-black-500": true,
  },
  "family:dropdown__loadMore": {
    "data-[is-active]:bg-blue-100": true,
  },
  "family:dropdown__dropdownWrapper": {
    "rounded-md": true,
    "empty:hidden": true,
    "mt-1.5": true,
    "dark:border-black-500": true,
    "ring-0": true,
    "shadow-md": true,
    "group-data-[overscroll]:shadow-none": true,
    "group-data-[overscroll]:border-none": true,
  },
  "family:dropdown__listbox": {
    "bg-white": true,
    "rounded-md": true,
    "overflow-clip": true,
    "empty:hidden": true,
    "dark:bg-black-900": true,
    "group-data-[overscroll]:border-1": true,
    "group-data-[overscroll]:shadow-md": true,
    "group-data-[overscroll]:border-black-500": true,
    "group-data-[overscroll]:dark:border-black-500": true,
  },
  "family:dropdown__listitemGroup": {
    "group/optgroup": true,
    "first:border-t-0": true,
    "last:border-b-0": true,
    "border-t": true,
    "border-b": true,
    "-mb-px": true,
    "border-black-500": true,
    "dark:border-black-500": true,
  },
  "family:dropdown__groupLabel": {
    block: true,
    "pt-1.5": true,
    "pb-1": true,
    "pl-4": true,
    "pr-6": true,
    "font-bold": true,
    "pointer-events-none": true,
    "text-black-500": true,
    "dark:text-black-500": true,
  },
  "family:dropdown__listitem": {
    relative: true,
    flex: true,
    "items-center": true,
    "pr-3": true,
    "py-3": true,
    "whitespace-nowrap": true,
    "h-8": true,
    "first:pt-3": true,
    "last:pb-3": true,
    "text-black-500": true,
    "text-sm": true,
    "bg-white": true,
    "data-[is-active]:bg-blue-500": true,
    "data-[is-active]:text-white": true,
    group: true,
    "before:content-['']": true,
    "before:absolute": true,
    "before:inset-0": true,
    "data-[is-active]:before:ring-1": true,
    "data-[is-active]:before:ring-inset": true,
    "data-[is-active]:before:ring-blue-300": true,
    "data-[is-active]:first:before:rounded-md": true,
    "data-[is-active]:first:before:rounded-b-none": true,
    "data-[is-active]:last:before:rounded-md": true,
    "data-[is-active]:last:before:rounded-t-none": true,
    "data-[is-active]:first:last:before:rounded-md": true,
    "dark:text-black-500": true,
    "dark:data-[is-active]:bg-blue-500": true,
    "dark:data-[is-active]:before:ring-blue-400": true,
    "group-[]/optgroup:first:before:!rounded-none": true,
    "group-[]/optgroup:last:before:!rounded-none": true,
  },
  "family:dropdown__selectedIcon": {
    flex: true,
    absolute: true,
    "group-data-[is-active]:text-white": true,
    "data-[is-active]:text-white": true,
    "items-center": true,
    "text-blue-500": true,
    "right-3": true,
    "ml-1.5": true,
    "text-lg": true,
    "h-[0.75em]": true,
    "w-[0.75em]": true,
    "shrink-0": true,
    "[&>svg]:w-full": true,
    "dark:text-blue-400": true,
  },
  "family:dropdown__option": {
    "ml-[1.25em]": true,
    "rounded-none": true,
    "bg-transparent": true,
  },
  "family:dropdown__optionLoading": {
    "": true,
  },
  "family:dropdown__emptyMessage": {
    flex: true,
    "items-center": true,
    "px-3": true,
    "py-1.5": true,
    "first:pt-3": true,
    "last:pb-3": true,
    "text-black-500": true,
    "text-sm": true,
    "dark:text-black-500": true,
  },
  "family:dropdown__emptyMessageInner": {
    "": true,
  },
  "family:dropdown__help": {
    "text-black-500": true,
    "text-sm": true,
    "dark:text-black-500": true,
  },
  "family:dropdown__messages": {
    "": true,
  },
  "family:dropdown__message": {
    "text-red-500": true,
    "mb-1.5": true,
    "text-sm": true,
    "dark:text-red-400": true,
  },
  button__outer: {
    group: true,
    "flex-grow": true,
    "min-w-0": true,
    "text-base": true,
    "data-[disabled]:select-none": true,
    "data-[disabled]:opacity-50": true,
    "data-[disabled]:pointer-events-none": true,
  },
  button__wrapper: {
    "": true,
  },
  button__label: {
    block: true,
    "text-black-500": true,
    "text-sm": true,
    "mb-1.5": true,
    "font-medium": true,
    "dark:text-black-500": true,
  },
  button__prefixIcon: {
    flex: true,
    "shrink-0": true,
    "items-center": true,
    "-ml-1.5": true,
    "mr-1.5": true,
    "text-base": true,
    "h-[1em]": true,
    "w-[1em]": true,
    "[&>svg]:w-full": true,
  },
  button__suffixIcon: {
    flex: true,
    "items-center": true,
    "-mr-1.5": true,
    "ml-1.5": true,
    "text-base": true,
    "h-[1em]": true,
    "w-[1em]": true,
    "shrink-0": true,
    "[&>svg]:w-full": true,
  },
  button__input: {
    "text-sm": true,
    "appearance-none": true,
    "outline-none": true,
    "[color-scheme:light]": true,
    "dark:[color-scheme:dark]": true,
    "placeholder:text-gray-400": true,
    "placeholder:whitespace-nowrap": true,
    "py-1.5": true,
  },
  button__help: {
    "text-black-500": true,
    "text-sm": true,
    "dark:text-black-500": true,
  },
  button__messages: {
    "": true,
  },
  button__message: {
    "text-red-500": true,
    "mb-1.5": true,
    "text-sm": true,
    "dark:text-red-400": true,
  },
  email__outer: {
    group: true,
    "flex-grow": true,
    "min-w-0": true,
    "text-base": true,
    "data-[disabled]:select-none": true,
    "data-[disabled]:opacity-50": true,
    "data-[disabled]:pointer-events-none": true,
  },
  email__wrapper: {
    "": true,
  },
  email__label: {
    block: true,
    "text-black-500": true,
    "text-sm": true,
    "mb-1.5": true,
    "font-medium": true,
    "dark:text-black-500": true,
  },
  email__prefixIcon: {
    flex: true,
    "shrink-0": true,
    "items-center": true,
    "-ml-1.5": true,
    "mr-1.5": true,
    "text-base": true,
    "h-[1em]": true,
    "w-[1em]": true,
    "[&>svg]:w-full": true,
  },
  email__suffixIcon: {
    flex: true,
    "items-center": true,
    "-mr-1.5": true,
    "ml-1.5": true,
    "text-base": true,
    "h-[1em]": true,
    "w-[1em]": true,
    "shrink-0": true,
    "[&>svg]:w-full": true,
  },
  email__inner: {
    "": true,
  },
  email__input: {
    "text-sm": true,
    "appearance-none": true,
    "outline-none": true,
    "[color-scheme:light]": true,
    "dark:[color-scheme:dark]": true,
    "placeholder:text-gray-400": true,
    "placeholder:whitespace-nowrap": true,
    "py-1.5": true,
  },
  email__help: {
    "text-black-500": true,
    "text-sm": true,
    "dark:text-black-500": true,
  },
  email__messages: {
    "": true,
  },
  email__message: {
    "text-red-500": true,
    "mb-1.5": true,
    "text-sm": true,
    "dark:text-red-400": true,
  },
  file__outer: {
    group: true,
    "flex-grow": true,
    "min-w-0": true,
    "text-base": true,
    "data-[disabled]:select-none": true,
    "data-[disabled]:opacity-50": true,
    "data-[disabled]:pointer-events-none": true,
    flex: true,
    "flex-col": true,
    "justify-center": true,
  },
  file__wrapper: {
    "": true,
  },
  file__label: {
    block: true,
    "text-black-500": true,
    "text-sm": true,
    "mb-1.5": true,
    "font-medium": true,
    "dark:text-black-500": true,
  },
  file__prefixIcon: {
    flex: true,
    "shrink-0": true,
    "items-center": true,
    "-ml-1.5": true,
    "mr-1.5": true,
    "text-base": true,
    "h-[1em]": true,
    "w-[1em]": true,
    "[&>svg]:w-full": true,
  },
  file__suffixIcon: {
    flex: true,
    "items-center": true,
    "-mr-1.5": true,
    "ml-1.5": true,
    "text-base": true,
    "h-[1em]": true,
    "w-[1em]": true,
    "shrink-0": true,
    "[&>svg]:w-full": true,
  },
  file__inner: {
    relative: true,
    "!ring-0": true,
    hidden: true,
    "group-data-[multiple]:rounded-md": true,
  },
  file__input: {
    "text-sm": true,
    "appearance-none": true,
    "outline-none": true,
    "[color-scheme:light]": true,
    "dark:[color-scheme:dark]": true,
    "placeholder:text-gray-400": true,
    "placeholder:whitespace-nowrap": true,
    "py-1.5": true,
    hidden: true,
  },
  file__fileList: {
    hidden: true,
  },
  file__fileItemIcon: {
    hidden: true,
  },
  file__fileItem: {
    hidden: true,
  },
  file__fileName: {
    hidden: true,
  },
  file__fileRemove: {
    hidden: true,
  },
  file__fileRemoveIcon: {
    hidden: true,
  },
  file__noFiles: {
    hidden: true,
  },
  file__noFilesIcon: {
    hidden: true,
  },
  file__help: {
    "text-black-500": true,
    "text-sm": true,
    "dark:text-black-500": true,
  },
  file__messages: {
    "": true,
  },
  file__message: {
    "text-red-500": true,
    "mb-1.5": true,
    "text-sm": true,
    "dark:text-red-400": true,
  },
  form__form: {
    "w-full": true,
  },
  form__actions: {
    "": true,
  },
  form__summaryInner: {
    "group/summary": true,
    border: true,
    "border-black-500": true,
    "rounded-md": true,
    "py-3": true,
    "px-9": true,
    "dark:border-black-500": true,
  },
  form__summaryHeader: {
    "text-lg": true,
    "text-black-500": true,
    "mb-3": true,
    "dark:text-black-500": true,
  },
  form__messages: {
    "": true,
  },
  form__message: {
    "text-red-500": true,
    "mb-1.5": true,
    "text-sm": true,
    "dark:text-red-400": true,
    "group-[]/summary:text-sm": true,
  },
  password__outer: {
    group: true,
    "flex-grow": true,
    "min-w-0": true,
    "text-base": true,
    "data-[disabled]:select-none": true,
    "data-[disabled]:opacity-50": true,
    "data-[disabled]:pointer-events-none": true,
  },
  password__wrapper: {
    "": true,
  },
  password__label: {
    block: true,
    "text-black-500": true,
    "text-sm": true,
    "mb-1.5": true,
    "font-medium": true,
    "dark:text-black-500": true,
  },
  password__prefixIcon: {
    flex: true,
    "shrink-0": true,
    "items-center": true,
    "-ml-1.5": true,
    "mr-1.5": true,
    "text-base": true,
    "h-[1em]": true,
    "w-[1em]": true,
    "[&>svg]:w-full": true,
  },
  password__suffixIcon: {
    flex: true,
    "items-center": true,
    "-mr-1.5": true,
    "ml-1.5": true,
    "text-base": true,
    "h-[1em]": true,
    "w-[1em]": true,
    "shrink-0": true,
    "[&>svg]:w-full": true,
  },
  password__inner: {
    "": true,
  },
  password__input: {
    "text-sm": true,
    "appearance-none": true,
    "outline-none": true,
    "[color-scheme:light]": true,
    "dark:[color-scheme:dark]": true,
    "placeholder:text-gray-400": true,
    "placeholder:whitespace-nowrap": true,
    "py-1.5": true,
  },
  password__help: {
    "text-black-500": true,
    "text-sm": true,
    "dark:text-black-500": true,
  },
  password__messages: {
    "": true,
  },
  password__message: {
    "text-red-500": true,
    "mb-1.5": true,
    "text-sm": true,
    "dark:text-red-400": true,
  },
  select__outer: {
    group: true,
    "flex-grow": true,
    "min-w-0": true,
    "text-base": true,
    "data-[disabled]:select-none": true,
    "data-[disabled]:opacity-50": true,
    "data-[disabled]:pointer-events-none": true,
  },
  select__wrapper: {
    "": true,
  },
  select__label: {
    block: true,
    "text-black-500": true,
    "text-sm": true,
    "mb-1.5": true,
    "font-medium": true,
    "dark:text-black-500": true,
  },
  select__prefixIcon: {
    flex: true,
    "shrink-0": true,
    "items-center": true,
    "mr-1.5": true,
    "text-base": true,
    "h-[1em]": true,
    "w-[1em]": true,
    "[&>svg]:w-full": true,
    "ml-3": true,
    "text-black-500": true,
    "dark:text-black-500": true,
  },
  select__suffixIcon: {
    flex: true,
    "items-center": true,
    "ml-1.5": true,
    "text-base": true,
    "h-[1em]": true,
    "w-[1em]": true,
    "shrink-0": true,
    "[&>svg]:w-full": true,
    "mr-3": true,
    "text-black-500": true,
    "dark:text-black-500": true,
  },
  select__inner: {
    relative: true,
    flex: true,
    "items-center": true,
    "border-black-500": true,
    "ring-1": true,
    "ring-gray-300": true,
    "rounded-md": true,
    "focus-within:ring-2": true,
    "focus-within:ring-blue-500": true,
    "group-data-[multiple]:rounded-md": true,
    "dark:border-black-500": true,
  },
  select__input: {
    "text-sm": true,
    "appearance-none": true,
    "outline-none": true,
    "[color-scheme:light]": true,
    "dark:[color-scheme:dark]": true,
    "placeholder:text-gray-400": true,
    "placeholder:whitespace-nowrap": true,
    "py-1.5": true,
    grow: true,
    "rounded-md": true,
    "font-semibold": true,
    "pr-[2em]": true,
    "h-8": true,
    "pl-3": true,
    "align-middle": true,
    "text-black-500": true,
    "text-ellipsis": true,
    "min-w-0": true,
    "group-data-[prefix-icon]:!pl-0": true,
    "group-data-[suffix-icon]:!pr-0": true,
    "data-[placeholder]:text-black-500": true,
    "data-[placeholder]:pl-3": true,
    "dark:text-black-500": true,
    "dark:data-[placeholder]:text-black-500": true,
    "border-none": true,
    "bg-none": true,
  },
  select__selectIcon: {
    absolute: true,
    "w-[1em]": true,
    "font-semibold": true,
    "text-black-500": true,
    "pointer-events-none": true,
    "right-3": true,
    "group-data-[suffix-icon]:mr-[1.5em]": true,
    "dark:text-black-500": true,
  },
  select__optGroup: {
    "group/optgroup": true,
    "bg-white": true,
    "text-black-500": true,
    "font-bold": true,
    "text-sm": true,
    "dark:text-black-500": true,
    "dark:bg-black-900": true,
  },
  select__option: {
    "text-black-500": true,
    "group-data-[multiple]:text-sm": true,
    "group-data-[multiple]:outline-none": true,
    "group-data-[multiple]:border-none": true,
    "group-data-[multiple]:py-1.5": true,
    "group-data-[multiple]:px-3": true,
    "dark:text-black-500": true,
    "dark:bg-black-900": true,
  },
  select__help: {
    "text-black-500": true,
    "text-sm": true,
    "dark:text-black-500": true,
  },
  select__messages: {
    "": true,
  },
  select__message: {
    "text-red-500": true,
    "mb-1.5": true,
    "text-sm": true,
    "dark:text-red-400": true,
  },
  text__outer: {
    group: true,
    "flex-grow": true,
    "min-w-0": true,
    "text-base": true,
    "data-[disabled]:select-none": true,
    "data-[disabled]:opacity-50": true,
    "data-[disabled]:pointer-events-none": true,
  },
  text__wrapper: {
    "": true,
  },
  text__label: {
    block: true,
    "text-black-500": true,
    "text-sm": true,
    "mb-1.5": true,
    "font-medium": true,
    "dark:text-black-500": true,
  },
  text__prefixIcon: {
    flex: true,
    "shrink-0": true,
    "items-center": true,
    "-ml-1.5": true,
    "mr-1.5": true,
    "text-base": true,
    "h-[1em]": true,
    "w-[1em]": true,
    "[&>svg]:w-full": true,
  },
  text__suffixIcon: {
    flex: true,
    "items-center": true,
    "-mr-1.5": true,
    "ml-1.5": true,
    "text-base": true,
    "h-[1em]": true,
    "w-[1em]": true,
    "shrink-0": true,
    "[&>svg]:w-full": true,
  },
  text__inner: {
    "": true,
  },
  text__input: {
    "text-sm": true,
    "appearance-none": true,
    "outline-none": true,
    "[color-scheme:light]": true,
    "dark:[color-scheme:dark]": true,
    "placeholder:text-gray-400": true,
    "placeholder:whitespace-nowrap": true,
    "py-1.5": true,
  },
  text__help: {
    "text-black-500": true,
    "text-sm": true,
    "dark:text-black-500": true,
  },
  text__messages: {
    "": true,
  },
  text__message: {
    "text-red-500": true,
    "mb-1.5": true,
    "text-sm": true,
    "dark:text-red-400": true,
  },
  textarea__outer: {
    group: true,
    "flex-grow": true,
    "min-w-0": true,
    "text-base": true,
    "data-[disabled]:select-none": true,
    "data-[disabled]:opacity-50": true,
    "data-[disabled]:pointer-events-none": true,
  },
  textarea__wrapper: {
    "": true,
  },
  textarea__label: {
    block: true,
    "text-black-500": true,
    "text-sm": true,
    "mb-1.5": true,
    "font-medium": true,
    "dark:text-black-500": true,
  },
  textarea__prefixIcon: {
    flex: true,
    "shrink-0": true,
    "items-center": true,
    "-ml-1.5": true,
    "mr-1.5": true,
    "text-base": true,
    "h-[1em]": true,
    "w-[1em]": true,
    "[&>svg]:w-full": true,
    "!ml-3": true,
    "!mr-0": true,
    "text-black-600": true,
    "dark:text-black-400": true,
  },
  textarea__suffixIcon: {
    flex: true,
    "items-center": true,
    "-mr-1.5": true,
    "ml-1.5": true,
    "text-base": true,
    "h-[1em]": true,
    "w-[1em]": true,
    "shrink-0": true,
    "[&>svg]:w-full": true,
    "!mr-3": true,
    "!ml-0": true,
    "text-black-600": true,
    "dark:text-black-400": true,
  },
  textarea__inner: {
    "w-full": true,
    flex: true,
    "items-center": true,
    "mb-1.5": true,
    "ring-1": true,
    "ring-gray-300": true,
    "rounded-none": true,
    "focus-within:ring-2": true,
    "focus-within:ring-blue-500": true,
    "dark:border-black-500": true,
  },
  textarea__input: {
    "appearance-none": true,
    "[color-scheme:light]": true,
    "dark:[color-scheme:dark]": true,
    "placeholder:text-gray-400": true,
    "placeholder:whitespace-nowrap": true,
    "py-1.5": true,
    "text-base": true,
    "h-24": true,
    "text-black-500": true,
    "min-w-0": true,
    grow: true,
    shrink: true,
    "!py-3": true,
    "!px-3": true,
    "outline-none": true,
    "bg-transparent": true,
    "dark:text-black-500": true,
  },
  textarea__help: {
    "text-black-500": true,
    "text-sm": true,
    "dark:text-black-500": true,
  },
  textarea__messages: {
    "": true,
  },
  textarea__message: {
    "text-red-500": true,
    "mb-1.5": true,
    "text-sm": true,
    "dark:text-red-400": true,
  },
  autocomplete__outer: {
    group: true,
    "flex-grow": true,
    "min-w-0": true,
    "text-base": true,
    "data-[disabled]:select-none": true,
    "data-[disabled]:opacity-50": true,
    "data-[disabled]:pointer-events-none": true,
  },
  autocomplete__wrapper: {
    "": true,
  },
  autocomplete__label: {
    block: true,
    "text-black-500": true,
    "text-sm": true,
    "mb-1.5": true,
    "font-medium": true,
    "dark:text-black-500": true,
  },
  autocomplete__inner: {
    "": true,
  },
  autocomplete__prefixIcon: {
    flex: true,
    "shrink-0": true,
    "items-center": true,
    "-ml-1.5": true,
    "mr-1.5": true,
    "text-base": true,
    "h-[1em]": true,
    "w-[1em]": true,
    "[&>svg]:w-full": true,
  },
  autocomplete__suffixIcon: {
    flex: true,
    "items-center": true,
    "-mr-1.5": true,
    "ml-1.5": true,
    "text-base": true,
    "h-[1em]": true,
    "w-[1em]": true,
    "shrink-0": true,
    "[&>svg]:w-full": true,
  },
  autocomplete__input: {
    "text-sm": true,
    "appearance-none": true,
    "outline-none": true,
    "[color-scheme:light]": true,
    "dark:[color-scheme:dark]": true,
    "placeholder:text-gray-400": true,
    "placeholder:whitespace-nowrap": true,
    "py-1.5": true,
  },
  autocomplete__selections: {
    flex: true,
    absolute: true,
    "inset-0": true,
    "group-data-[multiple]:static": true,
    "group-data-[multiple]:block": true,
    "group-data-[empty]:hidden": true,
    "group-data-[multiple]:mt-1.5": true,
  },
  autocomplete__selectionWrapper: {
    "rounded-md": true,
    "group-data-[multiple]:border": true,
    "group-data-[multiple]:border-black-500": true,
    "group-data-[multiple]:mb-1.5": true,
    "outline-none": true,
    "data-[active-selection=true]:ring-2": true,
    "dark:bg-black-700": true,
    "dark:group-data-[multiple]:border-black-500": true,
    "[&.formkit-dropZone]:opacity-50": true,
    "[&.formkit-touchDropZone]:opacity-50": true,
    "[&.formkit-touchDragging]:!flex": true,
    "[&.formkit-longTouch]:opacity-50": true,
  },
  autocomplete__selection: {
    "rounded-md": true,
    "pl-3": true,
    "[&>*]:ml-0": true,
  },
  autocomplete__loaderIcon: {
    "animate-spin": true,
    flex: true,
    "items-center": true,
    "my-auto": true,
    "text-base": true,
    "text-black-500": true,
    "h-[1em]": true,
    "dark:text-black-500": true,
  },
  autocomplete__removeSelection: {
    "": true,
  },
  autocomplete__listboxButton: {
    "": true,
  },
  autocomplete__selectIcon: {
    "": true,
  },
  autocomplete__dropdownWrapper: {
    "!max-w-screen-sm": true,
  },
  autocomplete__listbox: {
    "!max-w-screen-sm": true,
  },
  autocomplete__listitem: {
    "py-3": true,
    "pl-3": true,
    "min-h-8": true,
    "!h-auto": true,
    "group/autocomplete-option": true,
    "group-data-[is-active=true]/autocomplete-option:text-white": true,
    "!font-medium": true,
    "!whitespace-normal": true,
  },
  autocomplete__selectedIcon: {
    "text-blue": true,
    "!text-lg": true,
    "group-data-[is-active=true]/autocomplete-option:!text-white": true,
  },
  autocomplete__option: {
    "": true,
  },
  autocomplete__emptyMessage: {
    "": true,
  },
  autocomplete__emptyMessageInner: {
    "": true,
  },
  autocomplete__help: {
    "text-black-500": true,
    "text-sm": true,
    "dark:text-black-500": true,
  },
  autocomplete__messages: {
    "": true,
  },
  autocomplete__message: {
    "text-red-500": true,
    "mb-1.5": true,
    "text-sm": true,
    "dark:text-red-400": true,
  },
  datepicker__outer: {
    group: true,
    "flex-grow": true,
    "min-w-0": true,
    "text-base": true,
    "data-[disabled]:select-none": true,
    "data-[disabled]:opacity-50": true,
    "data-[disabled]:pointer-events-none": true,
  },
  datepicker__wrapper: {
    "": true,
  },
  datepicker__label: {
    block: true,
    "text-black-500": true,
    "text-sm": true,
    "mb-1.5": true,
    "font-medium": true,
    "dark:text-black-500": true,
  },
  datepicker__inner: {
    relative: true,
    "pl-0": true,
  },
  datepicker__prefixIcon: {
    flex: true,
    "shrink-0": true,
    "items-center": true,
    "-ml-1.5": true,
    "mr-1.5": true,
    "text-base": true,
    "h-[1em]": true,
    "w-[1em]": true,
    "[&>svg]:w-full": true,
  },
  datepicker__suffixIcon: {
    flex: true,
    "items-center": true,
    "-mr-1.5": true,
    "ml-1.5": true,
    "text-base": true,
    "h-[1em]": true,
    "w-[1em]": true,
    "shrink-0": true,
    "[&>svg]:w-full": true,
  },
  datepicker__removeSelection: {
    "": true,
  },
  datepicker__clearIcon: {
    flex: true,
    "items-center": true,
    "ml-1": true,
    "mr-3": true,
    "text-black-500": true,
    "text-base": true,
    "w-[0.75em]": true,
    "h-[0.75em]": true,
    "shrink-0": true,
    "[&>svg]:w-full": true,
  },
  datepicker__overlay: {
    "text-black-500": true,
    "dark:text-black-500": true,
  },
  datepicker__overlayInner: {
    "": true,
  },
  datepicker__overlayPlaceholder: {
    "text-black-500": true,
    "dark:text-black-500": true,
  },
  datepicker__overlayChar: {
    "text-black-500": true,
    "dark:text-black-500": true,
  },
  datepicker__overlayEnum: {
    "text-black-500": true,
    "dark:text-black-500": true,
  },
  datepicker__overlayLiteral: {
    "text-black-500": true,
    "dark:text-black-500": true,
  },
  datepicker__input: {
    "text-sm": true,
    "appearance-none": true,
    "outline-none": true,
    "[color-scheme:light]": true,
    "dark:[color-scheme:dark]": true,
    "placeholder:text-gray-400": true,
    "placeholder:whitespace-nowrap": true,
    "py-1.5": true,
    "w-1/2": true,
    "pl-3": true,
  },
  datepicker__openButton: {
    "appearance-none": true,
    "border-0": true,
    "bg-transparent": true,
    flex: true,
    "p-0": true,
    "self-stretch": true,
    "focus-visible:outline-none": true,
    "focus-visible:ring-2": true,
  },
  datepicker__calendarIcon: {
    "text-black-600": true,
    "focus-visible:text-blue-500": true,
    flex: true,
    "w-[1em]": true,
    "grow-0": true,
    "shrink-0": true,
    "self-stretch": true,
    "select-none": true,
    "[&>svg]:w-full": true,
    "[&>svg]:m-auto": true,
    "[&>svg]:max-h-[1em]": true,
    "[&>svg]:max-w-[1em]": true,
    "dark:text-black-400": true,
    "dark:focus-visible:text-blue-500": true,
  },
  datepicker__panelWrapper: {
    "group/panel": true,
    absolute: true,
    "min-w-[20em]": true,
    "top-full": true,
    "rounded-md": true,
    "p-3": true,
    "bg-white": true,
    border: true,
    "border-black-500": true,
    "z-[99]": true,
    "dark:bg-black-900": true,
    "dark:border-black-500": true,
    "[@media(max-width:431px)_and_(hover:none)]:group-[&:not([data-inline])]:!fixed":
      true,
    "[@media(max-width:431px)_and_(hover:none)]:group-[&:not([data-inline])]:top-auto":
      true,
    "[@media(max-width:431px)_and_(hover:none)]:group-[&:not([data-inline])]:max-w-none":
      true,
    "[@media(max-width:431px)_and_(hover:none)]:group-[&:not([data-inline])]:bottom-0":
      true,
    "[@media(max-width:431px)_and_(hover:none)]:group-[&:not([data-inline])]:left-0":
      true,
    "[@media(max-width:431px)_and_(hover:none)]:group-[&:not([data-inline])]:rounded-none":
      true,
    "[@media(max-width:431px)_and_(hover:none)]:group-[&:not([data-inline])]:w-full":
      true,
  },
  datepicker__panelHeader: {
    grid: true,
    "grid-cols-[2.5em_1fr_2.5em]": true,
    "justify-center": true,
    "items-center": true,
    "border-b-2": true,
    "border-black-500": true,
    "mb-3": true,
    "pb-6": true,
    "dark:border-black-500": true,
    "[@media(max-width:431px)_and_(hover:none)]:group-[&:not([data-inline])]:grid-cols-[2.5em_1fr_2.5em_2.5em]":
      true,
    "[@media(max-width:431px)_and_(hover:none)]:group-[&:not([data-inline])]:group-data-[panel=time]/panel:grid-cols-[2.5em_1fr_2.5em]":
      true,
    "[@media(max-width:431px)_and_(hover:none)]:group-[&:not([data-inline])]:group-data-[panel=month]/panel:grid-cols-[2.5em_1fr_2.5em]":
      true,
  },
  datepicker__panelClose: {
    "aspect-[1/1]": true,
    border: true,
    "border-black-400": true,
    "rounded-md": true,
    flex: true,
    "items-center": true,
    "justify-center": true,
    "text-black-500": true,
    "[&_svg]:w-[1.25em]": true,
    "dark:border-black-600": true,
    "dark:text-black-500": true,
    "[@media(max-width:431px)_and_(hover:none)]:group-data-[panel=time]/panel:col-start-3":
      true,
    "[@media(max-width:431px)_and_(hover:none)]:group-data-[panel=month]/panel:col-start-3":
      true,
  },
  datepicker__monthsHeader: {
    flex: true,
    "items-center": true,
    "justify-center": true,
    "col-start-2": true,
    "col-end-2": true,
  },
  datepicker__yearsHeader: {
    flex: true,
    "items-center": true,
    "justify-center": true,
    "text-black-500": true,
    "col-start-2": true,
    "col-end-2": true,
    "dark:text-black-500": true,
  },
  datepicker__timeHeader: {
    flex: true,
    "items-center": true,
    "justify-center": true,
    "col-start-2": true,
    "col-end-2": true,
  },
  datepicker__daysHeader: {
    flex: true,
    "items-center": true,
    "justify-center": true,
  },
  datepicker__prev: {
    "mr-auto": true,
    "px-6": true,
    "py-px": true,
    "col-start-1": true,
    "col-end-1": true,
    "focus-visible:outline-none": true,
    "focus-visible:ring-2": true,
  },
  datepicker__next: {
    "ml-auto": true,
    "px-6": true,
    "py-px": true,
    "col-start-3": true,
    "col-end-3": true,
    "focus-visible:outline-none": true,
    "focus-visible:ring-2": true,
  },
  datepicker__prevLabel: {
    hidden: true,
  },
  datepicker__nextLabel: {
    hidden: true,
  },
  datepicker__prevIcon: {
    flex: true,
    "w-[0.75em]": true,
    "select-none": true,
    "text-black-500": true,
    "[&>svg]:w-full": true,
    "dark:text-black-500": true,
  },
  datepicker__nextIcon: {
    flex: true,
    "w-[0.75em]": true,
    "select-none": true,
    "text-black-500": true,
    "[&>svg]:w-full": true,
    "dark:text-black-500": true,
  },
  datepicker__panel: {
    flex: true,
    "justify-center": true,
  },
  datepicker__months: {
    grid: true,
    "grid-cols-3": true,
    "w-full": true,
  },
  datepicker__month: {
    "m-3": true,
    "p-3": true,
    "text-center": true,
    "text-black-500": true,
    "bg-transparent": true,
    "aria-selected:!bg-blue-500": true,
    "aria-selected:!text-white": true,
    "focus:outline-none": true,
    "focus:ring-2": true,
    "focus:bg-white": true,
    "group-data-[disabled=true]:opacity-50": true,
    "group-data-[disabled=true]:pointer-events-none": true,
    "dark:text-black-500": true,
    "dark:aria-selected:!bg-blue-500": true,
    "dark:aria-selected:!text-black": true,
    "dark:focus:bg-black": true,
  },
  datepicker__years: {
    grid: true,
    "grid-cols-5": true,
    "w-full": true,
  },
  datepicker__year: {
    "text-sm": true,
    "text-center": true,
    "text-black-500": true,
    "items-center": true,
    "m-1": true,
    "p-1": true,
    "aria-selected:!bg-blue-500": true,
    "aria-selected:!text-white": true,
    "focus:outline-none": true,
    "focus:ring-2": true,
    "group-data-[disabled=true]:opacity-50": true,
    "group-data-[disabled=true]:pointer-events-none": true,
    "dark:text-black-500": true,
    "dark:aria-selected:!bg-blue-500": true,
    "dark:aria-selected:!text-black": true,
    "dark:focus:bg-black": true,
  },
  datepicker__weekDays: {
    grid: true,
    "grid-cols-7": true,
  },
  datepicker__weekDay: {
    "text-black-500": true,
    "m-1.5": true,
    "dark:text-black-500": true,
  },
  datepicker__week: {
    grid: true,
    "grid-cols-7": true,
    "group-data-[disabled=true]:opacity-50": true,
    "group-data-[disabled=true]:pointer-events-none": true,
  },
  datepicker__dayCell: {
    flex: true,
    "items-center": true,
    "justify-center": true,
    "text-center": true,
    "text-black-500": true,
    "w-[2.25em]": true,
    "h-[2.25em]": true,
    "m-1": true,
    "p-3": true,
    "bg-black-100": true,
    "aria-selected:bg-blue-500": true,
    "aria-selected:text-white": true,
    "focus:outline-none": true,
    "focus:ring-2": true,
    "focus:bg-white": true,
    "data-[disabled=true]:opacity-50": true,
    "data-[disabled=true]:pointer-events-none": true,
    "dark:text-black-500": true,
    "dark:bg-black-700": true,
    "dark:aria-selected:bg-blue-500": true,
    "dark:aria-selected:text-black": true,
    "dark:focus:bg-black": true,
  },
  datepicker__timeInput: {
    "w-full": true,
    "border-2": true,
    "[color-scheme:light]": true,
    "dark:[color-scheme:dark]": true,
    "text-black-500": true,
    "border-black-500": true,
    "rounded-md": true,
    "py-3": true,
    "px-9": true,
    "my-6": true,
    "focus-visible:outline-none": true,
    "focus-visible:ring-2": true,
    "dark:text-black-500": true,
    "dark:border-black-500": true,
  },
  datepicker__dayButton: {
    "appearance-none": true,
    "text-black-500": true,
    "px-6": true,
    "py-px": true,
    "border-2": true,
    "border-black-500": true,
    "mx-1": true,
    "focus-visible:outline-none": true,
    "focus-visible:ring-2": true,
    "dark:text-black-500": true,
    "dark:border-black-500": true,
  },
  datepicker__monthButton: {
    "appearance-none": true,
    "text-black-500": true,
    "px-6": true,
    "py-px": true,
    "border-2": true,
    "border-black-500": true,
    "mx-1": true,
    "focus-visible:outline-none": true,
    "focus-visible:ring-2": true,
    "dark:text-black-500": true,
    "dark:border-black-500": true,
  },
  datepicker__yearButton: {
    "appearance-none": true,
    "text-black-500": true,
    "px-6": true,
    "py-px": true,
    "border-2": true,
    "border-black-500": true,
    "mx-1": true,
    "focus-visible:outline-none": true,
    "focus-visible:ring-2": true,
    "dark:text-black-500": true,
    "dark:border-black-500": true,
  },
  datepicker__help: {
    "text-black-500": true,
    "text-sm": true,
    "dark:text-black-500": true,
  },
  datepicker__messages: {
    "": true,
  },
  datepicker__message: {
    "text-red-500": true,
    "mb-1.5": true,
    "text-sm": true,
    "dark:text-red-400": true,
  },
  dropdown__outer: {
    group: true,
    "flex-grow": true,
    "min-w-0": true,
    "text-base": true,
    "data-[disabled]:select-none": true,
    "data-[disabled]:opacity-50": true,
    "data-[disabled]:pointer-events-none": true,
  },
  dropdown__wrapper: {
    "": true,
  },
  dropdown__label: {
    block: true,
    "text-black-500": true,
    "text-sm": true,
    "mb-1.5": true,
    "font-medium": true,
    "dark:text-black-500": true,
  },
  dropdown__inner: {
    "": true,
  },
  dropdown__prefixIcon: {
    flex: true,
    "shrink-0": true,
    "items-center": true,
    "-ml-1.5": true,
    "mr-1.5": true,
    "text-base": true,
    "h-[1em]": true,
    "w-[1em]": true,
    "[&>svg]:w-full": true,
  },
  dropdown__suffixIcon: {
    flex: true,
    "items-center": true,
    "-mr-1.5": true,
    "ml-1.5": true,
    "text-base": true,
    "h-[1em]": true,
    "w-[1em]": true,
    "shrink-0": true,
    "[&>svg]:w-full": true,
  },
  dropdown__selector: {
    flex: true,
    grow: true,
    "gap-3": true,
    "justify-between": true,
    "w-full": true,
    "px-3": true,
    "py-1.5": true,
    "pr-0": true,
    "text-base": true,
    "text-black-500": true,
    "text-left": true,
    "group-data-[prefix-icon]:!pl-0": true,
    "group-data-[suffix-icon]:!pr-0": true,
    "data-[placeholder]:text-black-400": true,
    "dark:text-black-500": true,
    "dark:data-[placeholder]:text-black-500": true,
  },
  dropdown__placeholder: {
    "text-black": true,
    "dark:text-gray-400": true,
    "whitespace-nowrap": true,
  },
  dropdown__optionLoading: {
    "": true,
  },
  dropdown__selectionWrapper: {
    "": true,
  },
  dropdown__selection: {
    "[&>*]:ml-0": true,
  },
  dropdown__selectionsWrapper: {
    "w-[85%]": true,
    "overflow-hidden": true,
  },
  dropdown__selections: {
    "inline-flex": true,
    "items-center": true,
  },
  dropdown__selectionsItem: {
    "whitespace-nowrap": true,
    "mr-1": true,
  },
  dropdown__truncationCount: {
    flex: true,
    "items-center": true,
    "justify-center": true,
    "h-[1.5em]": true,
    "bg-black-500": true,
    "text-white": true,
    "whitespace-nowrap": true,
    "text-[11px]": true,
    "[line-height:1em]": true,
    "tracking-tighter": true,
    "leading-0": true,
    "py-0": true,
    "px-1": true,
    "shrink-0": true,
    "my-auto": true,
    "dark:bg-black-500": true,
    "dark:text-black-900": true,
  },
  dropdown__tagsWrapper: {
    "": true,
  },
  dropdown__tags: {
    "": true,
  },
  dropdown__tagWrapper: {
    "[&.formkit-dropZone_.formkit-tag]:opacity-50": true,
    "[&.formkit-touchDropZone_.formkit-tag]:opacity-50": true,
  },
  dropdown__tag: {
    "": true,
  },
  dropdown__tagLabel: {
    "": true,
  },
  dropdown__removeSelection: {
    "": true,
  },
  dropdown__selectIcon: {
    "shrink-0": true,
  },
  dropdown__dropdownWrapper: {
    "group-data-[overscroll]:m-0": true,
    "group-data-[overscroll]:shadow-none": true,
  },
  dropdown__listbox: {
    "": true,
  },
  dropdown__emptyMessage: {
    "": true,
  },
  dropdown__emptyMessageInner: {
    "": true,
  },
  dropdown__listItem: {
    "": true,
  },
  dropdown__selectedIcon: {
    "": true,
  },
  dropdown__option: {
    "": true,
  },
  dropdown__help: {
    "text-black-500": true,
    "text-sm": true,
    "dark:text-black-500": true,
  },
  dropdown__messages: {
    "": true,
  },
  dropdown__message: {
    "text-red-500": true,
    "mb-1.5": true,
    "text-sm": true,
    "dark:text-red-400": true,
  },
  repeater__outer: {
    "flex-grow": true,
    "min-w-0": true,
    "text-base": true,
    "group/repeater": true,
    "max-w-full": true,
  },
  repeater__fieldset: {
    "min-w-0": true,
  },
  repeater__legend: {
    block: true,
    "text-black-500": true,
    "text-sm": true,
    "dark:text-black-500": true,
    "mb-3": true,
  },
  repeater__help: {
    "text-black-500": true,
    "text-sm": true,
    "dark:text-black-500": true,
    "mb-3": true,
    "-mt-1": true,
  },
  repeater__inner: {
    "": true,
  },
  repeater__items: {
    "": true,
  },
  repeater__item: {
    flex: true,
    "w-full": true,
    "mb-3": true,
    border: true,
    "border-black-500": true,
    "rounded-md": true,
    "dark:border-black-500": true,
    "[&.formkit-dropZone]:opacity-30": true,
    "[&.formkit-dropZone]:pointer-events-none": true,
  },
  repeater__dragHandleWrapper: {
    relative: true,
    "w-8": true,
    "rounded-md": true,
    "rounded-tr-none": true,
    "rounded-br-none": true,
  },
  repeater__dragHandle: {
    "w-full": true,
    "h-full": true,
    flex: true,
    absolute: true,
    "top-0": true,
    "left-0": true,
    "cursor-grab": true,
    "active:cursor-grabbing": true,
  },
  repeater__dragHandleIcon: {
    "w-2": true,
    "m-auto": true,
    "text-black-500": true,
    "[&>svg>path]:fill-current": true,
  },
  repeater__content: {
    "min-w-0": true,
    grow: true,
    "p-12": true,
    flex: true,
    "flex-col": true,
    "align-center": true,
    "[&>div[data-type]]:max-w-none": true,
    "[&>div[data-type]:last-child]:mb-0": true,
  },
  repeater__controls: {
    flex: true,
    "flex-col": true,
    "items-center": true,
    "justify-center": true,
    "p-3": true,
    border: true,
    "ml-1.5": true,
    "[&>li]:w-[1.5em]": true,
    "[&>li]:h-[1.5em]": true,
    "[&>li]:my-1.5": true,
    "[&>li]:mx-auto": true,
    "[&>li]:flex": true,
    "[&>li]:items-center": true,
    "[&>li]:appearance-none": true,
    "[&>li]:justify-center": true,
    "[&>li]:aspect-[1/1]": true,
    "[&>li]:text-black-500": true,
    "[&>li:hover]:text-blue-600": true,
    "group-data-[disabled]/repeater:[&>li_button]:opacity-50": true,
    "group-data-[disabled]/repeater:[&>li_button]:!text-black-500": true,
    "group-data-[disabled]/repeater:[&>li_button]:pointer-events-none": true,
    "dark:[&>li]:text-black-500": true,
    "dark:[&>li:hover]:text-blue-200": true,
  },
  repeater__upControl: {
    "": true,
  },
  repeater__removeControl: {
    "": true,
  },
  repeater__insertControl: {
    "": true,
  },
  repeater__downControl: {
    "": true,
  },
  repeater__controlLabel: {
    absolute: true,
    "opacity-0": true,
    "pointer-events-none": true,
    "text-[0px]": true,
  },
  repeater__moveDownIcon: {
    block: true,
    "w-[0.75em]": true,
    "aspect-[1/1]": true,
  },
  repeater__moveUpIcon: {
    block: true,
    "w-[0.75em]": true,
    "aspect-[1/1]": true,
  },
  repeater__removeIcon: {
    block: true,
    "w-[1.25em]": true,
    "aspect-[1/1]": true,
  },
  repeater__addIcon: {
    block: true,
    "w-[1.25em]": true,
    "aspect-[1/1]": true,
  },
  repeater__addButton: {
    "!mb-0": true,
    "group-data-[disabled]/repeater:pointer-events-none": true,
    "group-data-[disabled]/repeater:opacity-50": true,
    "group-data-[disabled]/repeater:grayscale": true,
  },
  repeater__messages: {
    "": true,
  },
  repeater__message: {
    "text-red-500": true,
    "mb-1.5": true,
    "text-sm": true,
    "dark:text-red-400": true,
  },
  slider__outer: {
    group: true,
    "flex-grow": true,
    "min-w-0": true,
    "text-base": true,
    "data-[disabled]:select-none": true,
    "data-[disabled]:opacity-50": true,
    "data-[disabled]:pointer-events-none": true,
  },
  slider__wrapper: {
    "": true,
  },
  slider__label: {
    block: true,
    "text-black-500": true,
    "text-sm": true,
    "mb-1.5": true,
    "font-medium": true,
    "dark:text-black-500": true,
  },
  slider__help: {
    "text-black-500": true,
    "text-sm": true,
    "dark:text-black-500": true,
    "mb-1.5": true,
  },
  slider__prefixIcon: {
    flex: true,
    "shrink-0": true,
    "items-center": true,
    "-ml-1.5": true,
    "mr-1.5": true,
    "text-base": true,
    "h-[1em]": true,
    "w-[1em]": true,
    "[&>svg]:w-full": true,
    "text-black-600": true,
    "dark:text-black-400": true,
  },
  slider__suffixIcon: {
    flex: true,
    "items-center": true,
    "-mr-1.5": true,
    "ml-1.5": true,
    "text-base": true,
    "h-[1em]": true,
    "w-[1em]": true,
    "shrink-0": true,
    "[&>svg]:w-full": true,
    "text-black-600": true,
    "dark:text-black-400": true,
  },
  slider__sliderInner: {
    flex: true,
    "items-center": true,
    "[&>[data-type=number]]:mb-0": true,
    "[&>[data-type=number]]:ml-9": true,
    "[&>[data-type=number]]:shrink": true,
    "[&>[data-type=number]]:grow-0": true,
    "[&[data-has-mark-labels=true]_[id^=track]]:mb-3": true,
  },
  slider__track: {
    grow: true,
    relative: true,
    "z-20": true,
    "py-6": true,
    "select-none": true,
  },
  slider__chart: {
    relative: true,
    "w-full": true,
    flex: true,
    "items-center": true,
    "justify-between": true,
    "aspect-[3/1]": true,
    "mb-2": true,
    "z-20": true,
  },
  slider__chartBar: {
    absolute: true,
    "bottom-0": true,
    "h-full": true,
    "bg-black-400": true,
    "data-[active=true]:bg-black-500": true,
    "dark:bg-black-700": true,
    "dark:data-[active=true]:bg-black-500": true,
  },
  slider__trackWrapper: {
    "bg-gray-300": true,
    "px-2": true,
    "rounded-full": true,
    "dark:bg-black-700": true,
  },
  slider__trackInner: {
    relative: true,
    "h-1.5": true,
  },
  slider__fill: {
    "h-full": true,
    "rounded-md": true,
    absolute: true,
    "top-0": true,
    "-mx-1": true,
    "bg-blue-500": true,
    "dark:bg-blue-500": true,
  },
  slider__marks: {
    absolute: true,
    "pointer-events-none": true,
    "inset-0": true,
  },
  slider__mark: {
    absolute: true,
    "top-1/2": true,
    "w-[3px]": true,
    "h-[3px]": true,
    "rounded-full": true,
    "-translate-x-1/2": true,
    "-translate-y-1/2": true,
    "bg-black-500": true,
    "data-[active=true]:bg-white": true,
    "dark:bg-black-400": true,
    "dark:data-[active=true]:bg-black-600": true,
  },
  slider__markLabel: {
    absolute: true,
    "top-[calc(100%+0.5em)]": true,
    "left-1/2": true,
    "text-black-500": true,
    "text-xs": true,
    "-translate-x-1/2": true,
    "dark:text-black-400": true,
  },
  slider__handles: {
    "": true,
  },
  slider__handle: {
    "group/handle": true,
    "select-none": true,
    "w-4": true,
    "h-4": true,
    "rounded-full": true,
    "bg-white": true,
    absolute: true,
    "top-1/2": true,
    "left-0": true,
    "z-30": true,
    "-translate-x-1/2": true,
    "-translate-y-1/2": true,
    "border-2": true,
    "border-blue-500": true,
    "focus-visible:outline-0": true,
    "focus-visible:ring-2": true,
    "data-[is-target=true]:z-20": true,
  },
  slider__handleInner: {
    "": true,
  },
  slider__tooltip: {
    absolute: true,
    "bottom-full": true,
    "left-1/2": true,
    "-translate-x-1/2": true,
    "-translate-y-[4px]": true,
    "bg-blue-500": true,
    "text-white": true,
    "py-1": true,
    "px-1.5": true,
    "text-xs": true,
    "leading-none": true,
    "whitespace-nowrap": true,
    rounded: true,
    "opacity-0": true,
    "transition-opacity": true,
    "group-hover:opacity-100": true,
    "group-focus-visible/handle:opacity-100": true,
    "group-data-[show-tooltip=true]/handle:opacity-100": true,
    "dark:bg-blue-500": true,
    "dark:text-black-900": true,
    'after:content-[""]': true,
    "after:absolute": true,
    "after:top-full": true,
    "after:left-1/2": true,
    "after:-translate-x-1/2": true,
    "after:-translate-y-[1px]": true,
    "after:border-4": true,
    "after:border-transparent": true,
    "after:border-t-blue-500": true,
    "dark:after:border-t-blue-500": true,
  },
  slider__linkedValues: {
    flex: true,
    "items-start": true,
    "justify-between": true,
  },
  slider__minValue: {
    relative: true,
    grow: true,
    "!max-w-[45%]": true,
    "mb-0": true,
    '[&>div::after]:content-[""]': true,
    "[&>div::after]:absolute": true,
    "[&>div::after]:top-1/2": true,
    "[&>div::after]:left-[105%]": true,
    "[&>div::after]:-translate-y-1/2": true,
    "[&>div::after]:w-[12%]": true,
    "[&>div::after]:h-[1px]": true,
    "[&>div::after]:bg-black-500": true,
  },
  slider__maxValue: {
    relative: true,
    grow: true,
    "!max-w-[45%]": true,
    "mb-0": true,
  },
  slider__messages: {
    "": true,
  },
  slider__message: {
    "text-red-500": true,
    "mb-1.5": true,
    "text-sm": true,
    "dark:text-red-400": true,
  },
  toggle__outer: {
    group: true,
    "flex-grow": true,
    "min-w-0": true,
    "text-base": true,
    "data-[disabled]:select-none": true,
    "data-[disabled]:opacity-50": true,
    "data-[disabled]:pointer-events-none": true,
    "max-w-none": true,
  },
  toggle__wrapper: {
    flex: true,
    "flex-wrap": true,
    "items-center": true,
  },
  toggle__altLabel: {
    block: true,
    "w-full": true,
    "mb-1.5": true,
    "text-xs": true,
    "text-black-500": true,
    "dark:text-black-500": true,
  },
  toggle__inner: {
    "inline-block": true,
    "mr-3": true,
  },
  toggle__input: {
    "text-sm": true,
    "appearance-none": true,
    "outline-none": true,
    "[color-scheme:light]": true,
    "dark:[color-scheme:dark]": true,
    "placeholder:text-gray-400": true,
    "placeholder:whitespace-nowrap": true,
    "py-1.5": true,
    peer: true,
    absolute: true,
    "opacity-0": true,
    "w-0": true,
    "h-0": true,
  },
  toggle__track: {
    relative: true,
    "min-w-[3em]": true,
    "p-0.5": true,
    "select-none": true,
    "rounded-full": true,
    "transition-all": true,
    "bg-gray-400": true,
    "peer-focus-visible:ring-2": true,
    "peer-checked:bg-blue-500": true,
    "peer-checked:[&>div:last-child]:left-full": true,
    "peer-checked:[&>div:last-child]:-translate-x-full": true,
    "peer-checked:[&>div:first-child:not(:last-child)]:left-0": true,
    "peer-checked:[&>div:first-child:not(:last-child)]:translate-x-0": true,
    "dark:bg-black-600": true,
    "dark:peer-checked:bg-blue-500": true,
  },
  toggle__innerLabel: {
    absolute: true,
    "text-white": true,
    "text-[10px]": true,
    "left-full": true,
    "top-1/2": true,
    "-translate-x-full": true,
    "-translate-y-1/2": true,
    "px-1": true,
    "dark:text-black-900": true,
  },
  toggle__thumb: {
    relative: true,
    "w-[50%]": true,
    "aspect-[1/1]": true,
    "p-0.5": true,
    "left-0": true,
    "rounded-full": true,
    "transition-all": true,
    "bg-white": true,
    "text-black-500": true,
    "dark:bg-black-900": true,
    "dark:text-black-500": true,
  },
  toggle__thumbIcon: {
    "": true,
  },
  toggle__valueLabel: {
    "font-bold": true,
    "text-xs": true,
    "text-black-500": true,
    "dark:text-black-500": true,
  },
  toggle__label: {
    block: true,
    "text-black-500": true,
    "mb-1.5": true,
    "font-medium": true,
    "dark:text-black-500": true,
    "text-sm": true,
    "whitespace-nowrap": true,
    "last:mb-0": true,
  },
  toggle__help: {
    "text-black-500": true,
    "text-sm": true,
    "dark:text-black-500": true,
  },
  toggle__messages: {
    "": true,
  },
  toggle__message: {
    "text-red-500": true,
    "mb-1.5": true,
    "text-sm": true,
    "dark:text-red-400": true,
  },
  togglebuttons__options: {
    "group/options": true,
    "inline-flex": true,
    "data-[vertical=true]:flex-col": true,
  },
  togglebuttons__option: {
    "group/option": true,
  },
  togglebuttons__input: {
    "text-sm": true,
    "appearance-none": true,
    "outline-none": true,
    "[color-scheme:light]": true,
    "dark:[color-scheme:dark]": true,
    "placeholder:text-gray-400": true,
    "placeholder:whitespace-nowrap": true,
    "py-1.5": true,
    "!px-3": true,
    "group-data-[vertical=true]/options:w-full": true,
    "justify-center": true,
    "font-semibold": true,
    "disabled:opacity-50": true,
    "disabled:cursor-not-allowed": true,
    "group-data-[disabled]:disabled:opacity-100": true,
    "aria-[pressed=true]:bg-blue": true,
    "aria-[pressed=true]:text-white": true,
    "dark:aria-[pressed=true]:bg-blue-500": true,
    "dark:aria-[pressed=true]:text-white": true,
    "group-[]/option:!rounded-none": true,
    "group-data-[vertical=false]/options:group-first/option:!rounded-md": true,
    "group-data-[vertical=true]/options:group-first/option:!rounded-md": true,
    "group-data-[vertical=false]/options:group-first/option:!rounded-tr-none":
      true,
    "group-data-[vertical=false]/options:group-first/option:!rounded-br-none":
      true,
    "group-data-[vertical=true]/options:group-first/option:!rounded-bl-none":
      true,
    "group-data-[vertical=true]/options:group-first/option:!rounded-br-none":
      true,
    "group-data-[vertical=false]/options:group-last/option:!rounded-md": true,
    "group-data-[vertical=true]/options:group-last/option:!rounded-md": true,
    "group-data-[vertical=false]/options:group-last/option:!rounded-tl-none":
      true,
    "group-data-[vertical=false]/options:group-last/option:!rounded-bl-none":
      true,
    "group-data-[vertical=true]/options:group-last/option:!rounded-tl-none":
      true,
    "group-data-[vertical=true]/options:group-last/option:!rounded-tr-none":
      true,
    "group-data-[vertical=false]/options:group-[]/option:!border": true,
    "group-data-[vertical=false]/options:group-[]/option:!border-r-0": true,
    "group-data-[vertical=false]/options:group-last/option:!border-r": true,
    "group-data-[vertical=false]/options:group-[]/option:!border-gray-300":
      true,
    "group-data-[vertical=false]/options:group-[]/option:aria-[pressed=true]:border-x-blue-500":
      true,
    "group-data-[vertical=false]/options:group-first/option:aria-[pressed=true]:border-l-blue-500":
      true,
    "group-data-[vertical=false]/options:group-last/option:aria-[pressed=true]:border-r-blue-500":
      true,
    "dark:group-data-[vertical=false]/options:group-[]/option:aria-[pressed=true]:border-x-blue-500":
      true,
    "dark:group-data-[vertical=false]/options:group-first/option:aria-[pressed=true]:border-l-blue-500":
      true,
    "dark:group-data-[vertical=false]/options:group-last/option:aria-[pressed=true]:border-r-blue-500":
      true,
    "group-data-[vertical=true]/options:group-[]/option:!border-b-0": true,
    "group-data-[vertical=true]/options:group-last/option:!border-b": true,
    "group-data-[vertical=true]/options:group-[]/option:aria-[pressed=true]:border-y-blue-500":
      true,
    "group-data-[vertical=true]/options:group-first/option:aria-[pressed=true]:border-t-blue-500":
      true,
    "group-data-[vertical=true]/options:group-last/option:aria-[pressed=true]:border-b-blue-500":
      true,
    "dark:group-data-[vertical=true]/options:group-[]/option:aria-[pressed=true]:border-y-blue-500":
      true,
    "dark:group-data-[vertical=true]/options:group-first/option:aria-[pressed=true]:border-t-blue-500":
      true,
    "dark:group-data-[vertical=true]/options:group-last/option:aria-[pressed=true]:border-b-blue-500":
      true,
  },
  "multi-step__outer": {
    group: true,
    "flex-grow": true,
    "min-w-0": true,
    "text-base": true,
    "data-[disabled]:select-none": true,
    "data-[disabled]:opacity-50": true,
    "data-[disabled]:pointer-events-none": true,
    "group/multistep": true,
    "max-w-[32rem]": true,
  },
  "multi-step__wrapper": {
    "group/wrapper": true,
    "data-[tab-style=tab]:rounded-md": true,
  },
  "multi-step__tabs": {
    flex: true,
    "items-center": true,
    "group-data-[tab-style=tab]/wrapper:overflow-auto": true,
    "group-data-[tab-style=tab]/wrapper:border": true,
    "group-data-[tab-style=tab]/wrapper:border-b-0": true,
    "group-data-[tab-style=tab]/wrapper:border-black-500": true,
    "group-data-[tab-style=tab]/wrapper:rounded-md": true,
    "group-data-[tab-style=tab]/wrapper:rounded-bl-none": true,
    "group-data-[tab-style=tab]/wrapper:rounded-br-none": true,
    "dark:group-data-[tab-style=tab]/wrapper:border-black-500": true,
    "group-data-[tab-style=progress]/wrapper:my-12": true,
    "group-data-[tab-style=progress]/wrapper:justify-around": true,
    "group-data-[tab-style=progress]/wrapper:overflow-visible": true,
    "group-data-[tab-style=progress]/wrapper:group-data-[hide-labels=true]/wrapper:mb-12":
      true,
  },
  "multi-step__tab": {
    "group/tab": true,
    "group-data-[tab-style=tab]/wrapper:relative": true,
    "group-data-[tab-style=tab]/wrapper:flex": true,
    "group-data-[tab-style=tab]/wrapper:grow": true,
    "group-data-[tab-style=tab]/wrapper:text-sm": true,
    "group-data-[tab-style=tab]/wrapper:items-center": true,
    "group-data-[tab-style=tab]/wrapper:justify-center": true,
    "group-data-[tab-style=tab]/wrapper:cursor-pointer": true,
    "group-data-[tab-style=tab]/wrapper:text-black-700": true,
    "group-data-[tab-style=tab]/wrapper:bg-black-50": true,
    "group-data-[tab-style=tab]/wrapper:py-12": true,
    "group-data-[tab-style=tab]/wrapper:px-12": true,
    "group-data-[tab-style=tab]/wrapper:border-r": true,
    "group-data-[tab-style=tab]/wrapper:border-b": true,
    "group-data-[tab-style=tab]/wrapper:border-black-500": true,
    "group-data-[tab-style=tab]/wrapper:last:border-r-0": true,
    "group-data-[tab-style=tab]/wrapper:data-[active=true]:bg-white": true,
    "group-data-[tab-style=tab]/wrapper:data-[active=true]:border-b-transparent":
      true,
    "group-data-[tab-style=tab]/wrapper:data-[active=true]:z-10": true,
    "dark:group-data-[tab-style=tab]/wrapper:text-black-300": true,
    "dark:group-data-[tab-style=tab]/wrapper:bg-black-950/20": true,
    "dark:group-data-[tab-style=tab]/wrapper:data-[active=true]:bg-transparent":
      true,
    "dark:group-data-[tab-style=tab]/wrapper:data-[active=true]:border-b-transparent":
      true,
    "dark:group-data-[tab-style=tab]/wrapper:border-black-500": true,
    "group-data-[tab-style=progress]/wrapper:flex": true,
    "group-data-[tab-style=progress]/wrapper:flex-col": true,
    "group-data-[tab-style=progress]/wrapper:items-center": true,
    "group-data-[tab-style=progress]/wrapper:grow": true,
    "group-data-[tab-style=progress]/wrapper:shrink-0": true,
    "group-data-[tab-style=progress]/wrapper:relative": true,
    "group-data-[tab-style=progress]/wrapper:before:block": true,
    "group-data-[tab-style=progress]/wrapper:before:text-sm": true,
    "group-data-[tab-style=progress]/wrapper:before:w-[1.25rem]": true,
    "group-data-[tab-style=progress]/wrapper:before:h-[1.25rem]": true,
    "group-data-[tab-style=progress]/wrapper:before:border-4": true,
    "group-data-[tab-style=progress]/wrapper:before:border-black-300": true,
    "group-data-[tab-style=progress]/wrapper:before:rounded-full": true,
    "group-data-[tab-style=progress]/wrapper:before:bg-white": true,
    "group-data-[tab-style=progress]/wrapper:before:z-10": true,
    "dark:group-data-[tab-style=progress]/wrapper:before:border-black-700":
      true,
    "dark:group-data-[tab-style=progress]/wrapper:before:bg-black-950": true,
    "group-data-[tab-style=progress]/wrapper:after:block": true,
    "group-data-[tab-style=progress]/wrapper:after:h-1": true,
    "group-data-[tab-style=progress]/wrapper:after:w-full": true,
    "group-data-[tab-style=progress]/wrapper:after:absolute": true,
    "group-data-[tab-style=progress]/wrapper:after:top-[0.5em]": true,
    "group-data-[tab-style=progress]/wrapper:after:left-[calc(50%+0.5em)]":
      true,
    "group-data-[tab-style=progress]/wrapper:after:bg-black-300": true,
    "group-data-[tab-style=progress]/wrapper:data-[valid=true]:data-[visited=true]:after:bg-blue-900":
      true,
    "group-data-[tab-style=progress]/wrapper:last:after:hidden": true,
    "dark:group-data-[tab-style=progress]/wrapper:after:bg-black-700": true,
    "dark:group-data-[tab-style=progress]/wrapper:data-[valid=true]:data-[visited=true]:after:bg-blue-300":
      true,
  },
  "multi-step__tabLabel": {
    "group-data-[tab-style=progress]/wrapper:absolute": true,
    "group-data-[tab-style=progress]/wrapper:text-black-800": true,
    "group-data-[tab-style=progress]/wrapper:top-full": true,
    "group-data-[tab-style=progress]/wrapper:w-full": true,
    "group-data-[tab-style=progress]/wrapper:whitespace-nowrap": true,
    "group-data-[tab-style=progress]/wrapper:text-xs": true,
    "dark:group-data-[tab-style=progress]/wrapper:text-black-300": true,
  },
  "multi-step__badge": {
    "bg-blue-900": true,
    absolute: true,
    "font-mono": true,
    "font-bold": true,
    flex: true,
    "items-center": true,
    "justify-center": true,
    "aspect-[1/1]": true,
    "[line-height:1.25rem]": true,
    "text-white": true,
    "rounded-full": true,
    "dark:bg-blue-300": true,
    "dark:text-black-900": true,
    "group-data-[tab-style=tab]/wrapper:text-[0.66rem]": true,
    "group-data-[tab-style=tab]/wrapper:p-1.5": true,
    "group-data-[tab-style=tab]/wrapper:w-5": true,
    "group-data-[tab-style=tab]/wrapper:h-5": true,
    "group-data-[tab-style=tab]/wrapper:top-1.5": true,
    "group-data-[tab-style=tab]/wrapper:right-1.5": true,
    "group-data-[tab-style=progress]/wrapper:w-[1.25rem]": true,
    "group-data-[tab-style=progress]/wrapper:h-[1.25rem]": true,
    "group-data-[tab-style=progress]/wrapper:p-1": true,
    "group-data-[tab-style=progress]/wrapper:text-[10px]": true,
    "group-data-[tab-style=progress]/wrapper:[line-height:0]": true,
    "group-data-[tab-style=progress]/wrapper:z-10": true,
  },
  "multi-step__validStepIcon": {
    "w-full": true,
    "h-full": true,
    "mt-0.5": true,
  },
  "multi-step__steps": {
    "px-12": true,
    "pt-12": true,
    "pb-12": true,
    "bg-white": true,
    border: true,
    "border-black-500": true,
    "rounded-md": true,
    "dark:bg-transparent": true,
    "dark:border-black-500": true,
    "group-data-[tab-style=tab]/wrapper:border-t-0": true,
    "group-data-[tab-style=tab]/wrapper:rounded-tl-none": true,
    "group-data-[tab-style=tab]/wrapper:rounded-tr-none": true,
    "[&_[data-type]]:max-w-none": true,
  },
  step__stepActions: {
    flex: true,
    "justify-between": true,
    "[&>*]:grow-0": true,
  },
  step__stepPrevious: {
    "mr-1.5": true,
  },
  step__stepNext: {
    "ml-auto": true,
  },
};

/**
 * Globals are merged prior to generating this file — these are included for
 * any other non-matching inputs.
 **/
const globals: Record<string, Record<string, boolean>> = {
  outer: {
    group: true,
    "flex-grow": true,
    "min-w-0": true,
    "text-base": true,
    "data-[disabled]:select-none": true,
    "data-[disabled]:opacity-50": true,
    "data-[disabled]:pointer-events-none": true,
  },
  wrapper: {
    "": true,
  },
  label: {
    block: true,
    "text-black-500": true,
    "text-sm": true,
    "mb-1.5": true,
    "font-medium": true,
    "dark:text-black-500": true,
  },
  legend: {
    block: true,
    "text-black-500": true,
    "text-sm": true,
    "dark:text-black-500": true,
  },
  input: {
    "text-sm": true,
    "appearance-none": true,
    "outline-none": true,
    "[color-scheme:light]": true,
    "dark:[color-scheme:dark]": true,
    "placeholder:text-gray-400": true,
    "placeholder:whitespace-nowrap": true,
    "py-1.5": true,
  },
  placeholder: {
    "text-black": true,
    "dark:text-gray-400": true,
    "whitespace-nowrap": true,
  },
  prefixIcon: {
    flex: true,
    "shrink-0": true,
    "items-center": true,
    "-ml-1.5": true,
    "mr-1.5": true,
    "text-base": true,
    "h-[1em]": true,
    "w-[1em]": true,
    "[&>svg]:w-full": true,
  },
  suffixIcon: {
    flex: true,
    "items-center": true,
    "-mr-1.5": true,
    "ml-1.5": true,
    "text-base": true,
    "h-[1em]": true,
    "w-[1em]": true,
    "shrink-0": true,
    "[&>svg]:w-full": true,
  },
  loaderIcon: {
    "animate-spin": true,
    flex: true,
    "items-center": true,
    "my-auto": true,
    "text-base": true,
    "text-black-500": true,
    "h-[1em]": true,
    "dark:text-black-500": true,
  },
  loadMoreInner: {
    flex: true,
    "text-sm": true,
    "text-black-500": true,
    "pl-3": true,
    "dark:text-black-500": true,
  },
  help: {
    "text-black-500": true,
    "text-sm": true,
    "dark:text-black-500": true,
  },
  messages: {
    "": true,
  },
  message: {
    "text-red-500": true,
    "mb-1.5": true,
    "text-sm": true,
    "dark:text-red-400": true,
  },
  overlay: {
    "text-black-500": true,
    "dark:text-black-500": true,
  },
  overlayPlaceholder: {
    "text-black-500": true,
    "dark:text-black-500": true,
  },
  overlayLiteral: {
    "text-black-500": true,
    "dark:text-black-500": true,
  },
  overlayChar: {
    "text-black-500": true,
    "dark:text-black-500": true,
  },
  overlayEnum: {
    "text-black-500": true,
    "dark:text-black-500": true,
  },
};
