<script setup lang="ts">
import { computed } from "vue";
import { useRoute } from "vue-router";

interface Props {
  customerId: string;
}

defineProps<Props>();

const route = useRoute();
const title = computed(() => {
  if (route.path.includes("new")) return "New Ticket";
  return "Ticket Name";
});
</script>

<template>
  <div
    class="min-w-1/3 fixed bottom-0 right-2 z-[999] max-h-screen overflow-y-auto overscroll-y-contain rounded-lg rounded-bl-none rounded-br-none border-2 border-b-0 border-gray-300 bg-white shadow-lg"
  >
    <div class="flex justify-between border-b p-4 align-middle">
      <h1 :class="[route.name === 'newTicket' ? 'text-2xl font-medium' : 'text-base font-semibold']">{{ title }}</h1>
      <router-link :to="{ name: 'customerSupport', params: { customerId } }" data-testid="ticket-panel-dismiss">
        <i class="fa fa-times align-middle" />
      </router-link>
    </div>
    <slot />
  </div>
</template>
