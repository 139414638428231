<script setup lang="ts">
import { useRouter, useRoute } from "vue-router";
import { computed } from "vue";
import { SidebarMenuGroup } from "@/components/menus";
import useFeatureStore from "@/stores/features";
import { useUserStore } from "@/stores/user";

interface Props {
  isOpen: boolean;
}

defineProps<Props>();

const router = useRouter();
const route = useRoute();
const featureStore = useFeatureStore();
const userStore = useUserStore();

const links = computed(() => {
  const links = [
    {
      label: "Asset Visibility",
      icon: "light,diagram-sankey",
      to: router.resolve({ name: "assetsList", params: { companyId: userStore.companyId } }),
      current: route.name === "assetsList" || route.name === "assetLookup",
    },
  ];
  if (featureStore.features.hasFleetCyclesModule) {
    links.push({
      label: "Fleet Cycles",
      icon: "light,arrows-repeat",
      to: router.resolve({ name: "fleetPage", params: { companyId: userStore.companyId } }),
      current: route.name === "fleetPage",
    });
  }
  return links;
});
</script>

<template>
  <SidebarMenuGroup
    :is-open="isOpen"
    label="Visibility"
    icon="solid,project-diagram"
    :links="links"
    :current="route.path.includes('/visibility')"
  />
</template>
