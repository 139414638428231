import { defineStore } from "pinia";
import { ref, computed } from "vue";
import { v4 as uuid } from "uuid";
import App500Error from "../components/errors/App500Error.vue";
import App401Error from "../components/errors/App401Error.vue";
import App403Error from "../components/errors/App403Error.vue";
import { PlatformAlert } from "@/types";
import { useLDFlag } from "launchdarkly-vue-client-sdk";
import { watch } from "vue";
import dayjs from "dayjs";

type notificationType = "info" | "success" | "danger" | "loading";

type Toast = {
  status: notificationType;
  icon: string;
  message: string;
  id: string;
  noTimeout: boolean;
};

const useNotificationStore = defineStore("notifications", () => {
  const status = ref<"ready" | "loading" | "fault">("ready");
  const lastResponseCode = ref(0);
  const toastList = ref<Toast[]>([]);
  const loaderMessage = ref("");
  const showUpdateBanner = ref(false);
  const activeVersion = ref(import.meta.env.VITE_APP_VERSION);
  const platformAlert = useLDFlag<PlatformAlert | undefined>("platform-alert");
  const platformAlertDismissedAt = ref(localStorage.getItem("platformAlertDismissedAt") ?? undefined);
  const platformAlertEnabled = computed(
    () => platformAlert.value?.active === true && platformAlertDismissedAt.value === undefined,
  );

  watch(platformAlertDismissedAt, (next) => {
    if (next !== undefined) {
      localStorage.setItem("platformAlertDismissedAt", dayjs().toISOString());
      return;
    }
    localStorage.removeItem("platformAlertDismissedAt");
  });

  const statusPage = computed(() => {
    if (status.value !== "fault") return null;
    switch (lastResponseCode.value) {
      case 401:
        return App401Error;
      case 403:
        return App403Error;
      case 500:
        return App500Error;
      default:
        return App500Error;
    }
  });

  function dismissPlatformAlert() {
    platformAlertDismissedAt.value = dayjs().toISOString();
  }

  async function getLatestAppVersion(): Promise<string> {
    const response = await fetch("/version.txt", { cache: "no-store" }).catch((error) => {
      console.error(error);
      return;
    });
    if (!response?.ok) {
      console.error("HTTP Error: ", response?.status);
      return "";
    }
    return await response.text();
  }

  async function checkForNewerVersion(): Promise<void> {
    const currentVersion = await getLatestAppVersion();
    console.log(activeVersion.value);

    if (activeVersion.value < currentVersion && currentVersion.length) {
      showUpdateBanner.value = true;
      return;
    }
  }

  function setLoading(msg: string = "Loading...") {
    if (status.value === "fault") return;
    if (status.value === "loading") {
      status.value = "ready";
      loaderMessage.value = msg;
      return;
    }
    loaderMessage.value = msg;
    status.value = "loading";
  }

  function setToast(status: notificationType, message = "Something went wrong.", timeout = 5000) {
    const iconMap: { [key: string]: string } = {
      info: "circle-info",
      success: "circle-check",
      error: "circle-exclamation",
    };

    const id = uuid();
    toastList.value.push({
      id,
      status,
      icon: iconMap[status] || "circle-info",
      message,
      noTimeout: timeout === 0 ? true : false,
    });

    if (timeout) {
      setTimeout(() => clearToast(id), timeout);
    }

    return id;
  }
  function clearToast(id: string) {
    const idx = toastList.value.findIndex((t) => t.id === id);
    if (idx >= 0) {
      const newList = [...toastList.value];
      newList.splice(idx, 1);
      toastList.value = newList;
    }
  }

  return {
    status,
    lastResponseCode,
    toastList,
    loaderMessage,
    showUpdateBanner,
    activeVersion,
    statusPage,
    getLatestAppVersion,
    checkForNewerVersion,
    setLoading,
    setToast,
    clearToast,
    platformAlert,
    platformAlertEnabled,
    platformAlertDismissedAt,
    dismissPlatformAlert,
  };
});

export default useNotificationStore;
