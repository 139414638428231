<script setup lang="ts">
import { ref } from "vue";
import { DialogModal, TgCard, TgButton, BorderlessButton } from "@/components/common";
import useNotificationStore from "@/stores/notifications";
import { useFetch } from "@/composables/fetch";

const showModal = ref(false);

async function refreshData() {
  const notifier = useNotificationStore();
  notifier.setLoading();
  const request = useFetch();
  const response = await request.get("/admin/refreshdata");
  notifier.setLoading();

  if (!response.ok) {
    notifier.setToast("danger", "The refresh command was not successful.");
    console.error("Unable to refresh data: ", response.status);
    showModal.value = false;
    return undefined;
  }

  notifier.setToast("success", "Data refresh command successful.");
  showModal.value = false;
}
</script>

<template>
  <DialogModal :is-active="showModal" @dismiss="showModal = false">
    <TgCard>
      <template #title>Refresh App Data</template>
      <h1 class="mb-4 font-semibold">Are you sure?</h1>
      <p class="mb-2">This request typically takes 30s to complete.</p>
      <div class="flex flex-row-reverse items-center gap-2 border-t pt-2">
        <TgButton data-test="admin-refresh-submit" color="primary" @click="refreshData"> Yes, I'm sure </TgButton>
        <BorderlessButton color="danger" data-test="admin-refresh-cancel" :is-small="false" @click="showModal = false">
          Cancel
        </BorderlessButton>
      </div>
    </TgCard>
  </DialogModal>
  <TgButton id="start-refresh-data-button" color="info" :is-small="true" class="ml-4 mt-4" @click="showModal = true">
    Refresh App Data
  </TgButton>
</template>
