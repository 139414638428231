<script setup lang="ts">
import { computed } from "vue";

interface Props {
  url?: string;
  size: number;
  firstName?: string;
  lastName?: string;
  userInitials?: string;
  anon?: boolean;
  light?: boolean;
}

const initials = computed(() => {
  if (props.firstName && props.lastName) {
    return `${props.firstName?.charAt(0).toLocaleUpperCase()}${props.lastName?.charAt(0).toLocaleUpperCase()}`;
  }
  if (props.userInitials) {
    return props.userInitials;
  }
  return "";
});

const props = defineProps<Props>();
</script>

<template>
  <div class="m-0.5">
    <div
      v-if="props.url"
      :class="`h-${props.size} w-${props.size} rounded-full bg-cover bg-center bg-no-repeat`"
      :style="{ 'background-image': `url(${props.url})` }"
    ></div>
    <div
      v-else-if="props.anon"
      class="flex items-center justify-center rounded-full bg-gray-300 text-xl text-white"
      :class="`h-${props.size} w-${props.size}`"
    >
      <i class="fa fa-regular fa-user"></i>
    </div>
    <div
      v-else
      class="flex shrink-0 grow-0 items-center justify-center rounded-full border leading-[2.25rem]"
      :class="[
        light
          ? `h-${props.size} w-${props.size} bg-white text-gray-400 ring-1 ring-gray-300`
          : `h-${props.size} w-${props.size} bg-gray-700 text-gray-50`,
      ]"
    >
      {{ initials }}
    </div>
  </div>
</template>
