import { waybillActivityFeedFactory, waybillDetailsFactory } from "@/bapi-client/factories/waybills";
import { fetchHandler } from "@/bapi-client/fetch";
import { BAPI_COMMANDS } from "@/bapi-client/types/commands";
import { HandlerParams } from "@/bapi-client/types/handlerParams";
import { HandlerResponse } from "@/bapi-client/types/handlerResponse";

export async function getWaybillDetailsActivityFeed(
  ...[customerId, waybillId]: HandlerParams[typeof BAPI_COMMANDS.WAYBILL_GET_ACTIVITY_FEED]
) {
  const response = await fetchHandler(
    BAPI_COMMANDS.WAYBILL_GET_ACTIVITY_FEED,
    "GET",
    `/waybills/${customerId}/activity_feed/${waybillId}/diff`,
  );
  if (!response.success) return response;
  const parsed = await response.data.json();
  if (!parsed.success) return parsed;
  return {
    success: true,
    data: parsed.data.data.map((item) => waybillActivityFeedFactory(item)),
  };
}

export async function getWaybillDetails(
  ...[customerId, waybillId]: HandlerParams[typeof BAPI_COMMANDS.WAYBILL_GET_WAYBILL_DETAILS]
): HandlerResponse[typeof BAPI_COMMANDS.WAYBILL_GET_WAYBILL_DETAILS] {
  const response = await fetchHandler(
    BAPI_COMMANDS.WAYBILL_GET_WAYBILL_DETAILS,
    "GET",
    `/waybills/${customerId}/detail/${waybillId}`,
  );
  if (!response.success) return response;
  const parsed = await response.data.json();
  if (!parsed.success) return parsed;
  return {
    success: true,
    data: waybillDetailsFactory(parsed.data),
  };
}
