<script setup lang="ts">
import { ref, computed } from "vue";
import { useRouter } from "vue-router";
import { Listbox, ListboxButton, ListboxOption, ListboxOptions } from "@headlessui/vue";
import { useUserStore } from "@/stores/user";
import { toAlphanumeric } from "@/utils/text";
import { sortByStringProperty } from "@/utils/sorters";

const router = useRouter();
const userStore = useUserStore();

const customer = computed({
  get() {
    return userStore?.companyId;
  },
  async set(companyId: string) {
    await userStore.setUserCustomer(companyId);
    return router.push(userStore.landingPage);
  },
});

const customerSearch = ref("");

const customerOptions = computed(() => {
  if (!userStore.user?.companies) {
    return [];
  }
  const customerList = userStore.user.companies.map((company) => {
    return { label: company.name, value: company.id, searchTag: toAlphanumeric(company.name) };
  });

  if (customerSearch.value) {
    return customerList.filter((cust) => cust.searchTag.includes(toAlphanumeric(customerSearch.value)));
  }

  return sortByStringProperty(customerList, "label", "ASC");
});

const showSelect = computed(() => {
  if (!userStore.user?.companies) {
    return false;
  }
  if (userStore.user.companies.length <= 1) {
    return false;
  }
  return true;
});
</script>

<template>
  <div v-if="!showSelect" class="relative pl-2">
    <span data-test="sidebar-customer-name" class="whitespace-normal text-lg font-semibold text-white">
      {{ userStore.company?.name }}
    </span>
  </div>
  <Listbox v-else v-model="customer" as="div" data-test="sidebar-customer-menu" class="w-full">
    <div class="relative w-[10rem] pl-2">
      <ListboxButton as="a" class="group">
        <span class="w-full text-lg font-semibold text-white">{{ userStore.company?.name }}</span>
        <p class="whitespace-nowrap text-sm text-blue-sky-300">
          <span class="whitespace-nowrap transition-all group-hover:text-white group-hover:underline"
            >Change customer</span
          >
          <i class="fa-light fa-angle-down ml-4 group-hover:font-bold group-hover:text-white"></i>
        </p>
      </ListboxButton>

      <transition
        leave-active-class="transition ease-in duration-100"
        leave-from-class="opacity-100"
        leave-to-class="opacity-0"
      >
        <ListboxOptions
          class="absolute z-40 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
        >
          <ListboxOption
            v-for="option in customerOptions"
            :key="option.value"
            v-slot="{ active, selected }"
            as="template"
            :value="option.value"
          >
            <li
              :title="option.label"
              :class="[
                active ? 'bg-blue-500 text-white' : 'text-gray-900',
                'relative cursor-default select-none py-2 pl-3 pr-9',
              ]"
            >
              <span :class="[selected ? 'font-semibold' : 'font-normal', 'block truncate']">{{ option.label }}</span>

              <span
                v-if="selected"
                :class="[active ? 'text-white' : 'text-blue-500', 'absolute inset-y-0 right-0 flex items-center pr-4']"
              >
                <i class="fa-solid fa-square-check"></i>
              </span>
            </li>
          </ListboxOption>
        </ListboxOptions>
      </transition>
    </div>
  </Listbox>
</template>
