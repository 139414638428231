<script setup lang="ts">
import { computed } from "vue";
import { useUserStore } from "@/stores/user";
import { ProfilePicture } from "@/components/common";

interface Props {
  sidebarOpen: boolean;
}

defineProps<Props>();

const store = useUserStore();

const bgClasses = computed(() => {
  return import.meta.env.PROD
    ? "border-blue-midnight-600 bg-gradient-to-r from-blue-midnight-400 to-blue-midnight-300"
    : "border-purple-950 bg-purple-950";
});

const fullName = computed(() => {
  return `${store.user?.firstName} ${store.user?.lastName}`;
});
const profilePicture = computed(() => {
  return `${store.user?.profilePicture?.thumbnailUrl}`;
});
</script>

<template>
  <div class="flex flex-shrink-0 flex-col items-center border-t px-6 py-3" :class="bgClasses">
    <div class="flex items-center justify-center" :class="[sidebarOpen && 'pl-3']">
      <router-link :to="{ name: 'userProfile' }" class="inline-block">
        <ProfilePicture
          :url="profilePicture"
          :size="9"
          :first-name="store.user?.firstName"
          :last-name="store.user?.lastName"
          :light="true"
        />
      </router-link>
      <div v-if="sidebarOpen" class="grow">
        <a :class="[sidebarOpen ? 'pl-4' : '']" class="group block w-full flex-shrink-0" @click.prevent>
          <p class="text-md font-medium text-white">{{ fullName }}</p>
          <router-link
            :to="{ name: 'login' }"
            class="inline-block w-48 text-sm font-medium transition-all group-hover:text-white group-hover:underline"
            @click="store.logoutUser"
          >
            <i class="fa-light fa-arrow-right-from-bracket text-blue-50"></i>
            <span class="ml-1 text-blue-50 transition-all group-hover:ml-2">Sign out</span>
          </router-link>
        </a>
      </div>
    </div>
  </div>
</template>
