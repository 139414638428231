export function toTitleCase(string: string): string {
  const lowercaseWords = [
    "a",
    "an",
    "the",
    "and",
    "but",
    "or",
    "for",
    "nor",
    "as",
    "at",
    "by",
    "for",
    "from",
    "in",
    "into",
    "near",
    "of",
    "on",
    "onto",
    "to",
    "with",
  ];
  return string
    .toLowerCase()
    .split(" ")
    .map((sub: string, index) => {
      if (lowercaseWords.includes(sub) && index !== 0) {
        return sub;
      }
      return sub[0].toUpperCase() + sub.substring(1);
    })
    .join(" ");
}

export function snakeToUpperCase(str: string): string {
  return str
    .split("_")
    .map((sub: string) => sub.charAt(0).toUpperCase() + sub.substring(1))
    .join(" ");
}

export function snakeToCamelCase(str: string): string {
  return str
    .split("_")
    .map((sub, idx) => {
      // Skip the first word, it's camelCase not PascalCase
      if (idx > 0) {
        return sub.charAt(0).toUpperCase() + sub.substring(1);
      }
      return sub;
    })
    .join("");
}

export function toAlphanumeric(str: string): string {
  return str.toLocaleUpperCase().replace(/[^A-Z0-9]*/g, "");
}
