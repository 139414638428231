import { BAPI_COMMANDS } from "../types/commands";
import type { HandlerParams } from "../types/handlerParams";
import type { HandlerResponse } from "../types/handlerResponse";
import type { BAPIParams } from "../types/bapiParams";
import { fetchHandler } from "../fetch";
import { templateFactory, templateDataFieldFactory } from "@/factories/templateFactory";

export async function getReportTemplate(
  customerId: string,
  templateId: string,
): HandlerResponse[typeof BAPI_COMMANDS.GET_REPORT_TEMPLATE] {
  const path = `/reports/${customerId}/template/${templateId}`;

  const response = await fetchHandler(BAPI_COMMANDS.GET_REPORT_TEMPLATE, "GET", path, {
    canAbort: true,
  });

  if (!response.success) {
    return response;
  }

  const { data } = response;
  const jsonResult = await data.json();

  if (!jsonResult.success) {
    return jsonResult;
  }

  return {
    success: true,
    data: templateFactory(jsonResult.data),
  };
}

export async function getReportTemplates(
  opts: HandlerParams[typeof BAPI_COMMANDS.GET_REPORT_TEMPLATES],
): HandlerResponse[typeof BAPI_COMMANDS.GET_REPORT_TEMPLATES] {
  const path = `/reports/${opts.customerId}/template`;

  const response = await fetchHandler(BAPI_COMMANDS.GET_REPORT_TEMPLATES, "GET", path, {
    canAbort: true,
  });

  if (!response.success) {
    return response;
  }

  const { data } = response;
  const jsonResult = await data.json();

  if (!jsonResult.success) {
    return jsonResult;
  }

  return {
    success: true,
    data: jsonResult.data.map(templateFactory),
  };
}

export async function deleteReportTemplate(
  opts: HandlerParams[typeof BAPI_COMMANDS.DELETE_REPORT_TEMPLATE],
): HandlerResponse[typeof BAPI_COMMANDS.DELETE_REPORT_TEMPLATE] {
  const response = await fetchHandler(
    "deleteReportTemplate",
    "DELETE",
    `/reports/${opts.customerId}/template/${opts.templateId}`,
  );

  if (!response.success) {
    return response;
  }

  const { data } = response;
  return await data.json();
}

export async function createReportTemplate(
  opts: HandlerParams[typeof BAPI_COMMANDS.CREATE_REPORT_TEMPLATE],
): HandlerResponse[typeof BAPI_COMMANDS.CREATE_REPORT_TEMPLATE] {
  const formData: BAPIParams[typeof BAPI_COMMANDS.CREATE_REPORT_TEMPLATE] = {
    is_public: opts.isPublic,
    is_equipment_centric: opts.isEquipmentCentric,
    selected_columns: opts.selectedColumns,
    template_name: opts.templateName,
  };
  // add group by columns if present for BASF
  if (opts.selectedGroupByColumns) {
    formData.selected_group_by_columns = opts.selectedGroupByColumns;
  }

  const response = await fetchHandler(
    BAPI_COMMANDS.CREATE_REPORT_TEMPLATE,
    "POST",
    `/reports/${opts.customerId}/template`,
    {
      body: formData,
    },
  );

  if (!response.success) {
    return response;
  }

  const result = await response.data.json();
  return result.success ? { success: true, data: templateFactory(result.data) } : result;
}

export async function updateReportTemplate(
  opts: HandlerParams[typeof BAPI_COMMANDS.UPDATE_REPORT_TEMPLATE],
): HandlerResponse[typeof BAPI_COMMANDS.UPDATE_REPORT_TEMPLATE] {
  const formData: BAPIParams[typeof BAPI_COMMANDS.CREATE_REPORT_TEMPLATE] = {
    is_public: opts.isPublic,
    is_equipment_centric: opts.isEquipmentCentric,
    selected_columns: opts.selectedColumns,
    template_name: opts.templateName,
  };
  // add group by columns if present for BASF
  if (opts.selectedGroupByColumns) {
    formData.selected_group_by_columns = opts.selectedGroupByColumns;
  }

  const response = await fetchHandler(
    BAPI_COMMANDS.UPDATE_REPORT_TEMPLATE,
    "PUT",
    `/reports/${opts.customerId}/template/${opts.templateId}`,
    {
      body: formData,
    },
  );

  if (!response.success) {
    return response;
  }

  const result = await response.data.json();
  return result.success ? { success: true, data: templateFactory(result.data) } : result;
}

export async function getReportTemplateFields(
  opts: HandlerParams[typeof BAPI_COMMANDS.GET_REPORT_TEMPLATE_FIELDS],
): HandlerResponse[typeof BAPI_COMMANDS.GET_REPORT_TEMPLATE_FIELDS] {
  const response = await fetchHandler(
    BAPI_COMMANDS.GET_REPORT_TEMPLATE_FIELDS,
    "GET",
    `/reports/${opts.customerId}/template/columns`,
  );

  if (!response.success) {
    return response;
  }

  const jsonResult = await response.data.json();
  if (!jsonResult.success) {
    return jsonResult;
  }

  const { data } = jsonResult;

  return {
    success: true,
    data: {
      columnCount: data.column_count,
      categories: data.categories,
      columns: data.columns.map(templateDataFieldFactory),
    },
  };
}
