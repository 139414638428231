<script setup lang="ts">
import { computed } from "vue";
import type { TableColumn } from "@/types";

interface Props {
  columns?: TableColumn[];
  sortColumn?: number | string | string[];
  sortDirection?: string;
  withStickyColumn?: boolean;
  isFixed?: boolean;
}

const props = withDefaults(defineProps<Props>(), {
  withStickyColumn: false,
  isFixed: false,
  columns: () => [],
  sortColumn: "",
  sortDirection: "ASC",
});

const emit = defineEmits<{
  (event: "sort", value: string | number | string[]): void;
}>();

const stickyClasses = computed(() =>
  props.withStickyColumn ? "first:sticky first:left-0 first:z-30 first:border-r-0" : "",
);

const columnMap = computed(() => {
  return props.columns?.map((column) => {
    if (typeof column === "string") {
      return { label: column, value: column, canSort: false };
    }
    return { ...column, canSort: true };
  });
});
</script>

<template>
  <div>
    <table class="table w-full shrink text-left" :class="[isFixed ? 'table-fixed' : 'table-auto']">
      <thead class="sticky top-0 z-20 bg-white" role="rowgroup">
        <slot name="header" />
        <template v-if="!$slots.header">
          <th
            v-for="column in columnMap"
            :key="column.value.toString()"
            class="whitespace-nowrap bg-gray-300 px-6 py-3 text-left text-base font-semibold leading-tight text-gray-900 after:absolute after:bottom-0 after:left-0 after:w-full after:border-b after:border-b-gray-900"
            :class="stickyClasses"
            role="columnheader"
          >
            <template v-if="column.canSort">
              <span
                class="cursor-pointer transition-all hover:text-blue"
                :class="{ 'text-blue': sortColumn === column.value }"
                @click="emit('sort', column.value)"
              >
                <span data-cy="column-label">{{ column.label }}</span>
                <span class="ml-3">
                  <i
                    data-test="table-sort-icon"
                    class="fa-regular fa-angle-down transition-all"
                    :class="[sortColumn === column.value && sortDirection === 'ASC' ? 'rotate-180' : 'rotate-0']"
                  ></i>
                </span>
              </span>
            </template>
            <template v-else>
              <span class="tg-table__column-label">
                <span data-cy="column-label">{{ column.label }}</span>
              </span>
            </template>
          </th>
        </template>
      </thead>
      <tbody class="tg-table__body">
        <slot></slot>
      </tbody>
    </table>
    <div v-if="$slots.footer" class="border-t">
      <slot name="footer" />
    </div>
  </div>
</template>
