<script setup lang="ts">
import { ref } from "vue";
import { useBapi } from "@/bapi-client";
import { Option } from "@/types";

interface Props {
  customerId: string;
  labelClasses: string;
}

const props = defineProps<Props>();
const optionList = ref<Option[]>([]);

async function searchAssets({ search }: { search: string }): Promise<Option[]> {
  if (!search || search.length < 3) {
    return optionList.value;
  }

  const result = await useBapi("autocompleteAssets", props.customerId, {
    searchTerm: search,
  });

  if (!result.success) {
    return optionList.value;
  }

  const out: Option[] = [];
  for (const i of result.data) {
    if (!optionList.value.find((opt) => opt.value === i.equipmentId)) {
      out.push({
        label: i.equipmentId,
        value: i.equipmentId,
      });
    }
  }

  return optionList.value.concat(out);
}

async function optionLoader(value: string, cached?: Option): Promise<Option> {
  if (cached) {
    if (!optionList.value.find((opt) => opt.value === cached.value)) {
      optionList.value.push(cached);
    }
    return cached;
  }

  const result = await useBapi("autocompleteAssets", props.customerId, {
    searchTerm: value,
  });

  if (!result.success || !result.data.length) {
    return { label: "No matching equipment found.", value: "" };
  }

  const out: Option[] = [];
  for (const i of result.data) {
    out.push({
      label: i.equipmentId,
      value: i.equipmentId,
    });
  }

  optionList.value.concat(out);
  return out[0];
}
</script>

<template>
  <FormKit
    type="autocomplete"
    label="Asset ID"
    :classes="{
      label: labelClasses + ' grow',
      outer: '!grow-0 content-center',
      wrapper: 'flex gap-2 grow',
      inner: 'w-40',
      selectionWrapper: 'bg-white border-none rounded-md m-px justify-between',
      selection: 'font-semibold',
    }"
    :close-on-select="true"
    selection-appearance="option"
    loader-icon="loader"
    :options="searchAssets"
    :option-loader="optionLoader"
    empty-message="No equipment found."
    placeholder="- -"
  >
    <template #loadMore></template>
  </FormKit>
</template>
