import type { BAPITemplate, BAPITemplateField } from "@/bapi-client/types/reportTemplate";
import type { ReportTemplate, ReportTemplateField } from "@/types/reportTemplate";

export function templateFactory(data: BAPITemplate): ReportTemplate {
  return {
    templateId: data.template_id,
    templateName: data.template_name,
    ownerId: data.owner_id,
    ownerName: data.owner_name,
    isPublic: data.is_public,
    isEquipmentCentric: data.is_equipment_centric,
    lastModified: data.last_modified,
    selectedColumns: data.selected_columns.map((col) => {
      return {
        id: col.id,
        order: col.order,
        fieldName: col.field_name,
        fieldLabel: col.field_label,
        fieldType: col.field_type,
        categories: col.categories,
      };
    }),
    selectedGroupByColumns: data.selected_group_by_columns?.map((col) => {
      return {
        id: col.id,
        order: col.order,
        fieldName: col.field_name,
        fieldLabel: col.field_label,
        fieldType: col.field_type,
        categories: col.categories,
      };
    }),
    reportCountTotal: data.report_count_total,
    reportCountOwner: data.report_count_owner,
    reportCountOther: data.report_count_other,
    canDelete: data.can_delete,
  };
}

export function templateDataFieldFactory(data: BAPITemplateField): ReportTemplateField {
  return {
    id: data.id,
    categories: data.categories,
    fieldName: data.field_name,
    fieldType: data.field_type,
    fieldLabel: data.field_label,
  };
}
