<script setup lang="ts">
import { computed } from "vue";
import { RouteLocation } from "vue-router";

interface Props {
  to?: Partial<RouteLocation>;
  label: string;
  icon: string;
  current: boolean;
  isOpen: boolean;
}

const props = defineProps<Props>();

const iconClass = computed(() => {
  return props.icon
    .split(",")
    .map((sub) => "fa-" + sub)
    .join(" ");
});
</script>

<template>
  <component
    :is="to ? 'RouterLink' : 'div'"
    :to="to"
    class="mx-2"
    :class="[
      current
        ? 'bg-blue-500 text-blue-sky-300'
        : 'text-blue-sky-300 hover:bg-blue-100 hover:bg-opacity-80 hover:text-blue-sky-100',
      'text-md group flex items-center rounded-md py-2 font-medium transition-all',
      !isOpen ? 'justify-center text-center' : 'px-2',
    ]"
  >
    <i :class="[iconClass, current ? 'text-white' : '']" class="w-6 flex-shrink-0 text-lg" aria-hidden="true" />
    <span v-if="isOpen" class="pl-2 text-base" :class="{ 'text-white': current }">{{ label }}</span>
  </component>
</template>
