export const tableauReportTypes: { [key: string]: { label: string; icon: string } } = {
  cycle: {
    label: "Cycle Analytics",
    icon: "solid,arrows-spin",
  },
  fleet: {
    label: "Fleet Analytics",
    icon: "solid,container-storage",
  },
  terminal: {
    label: "Terminal Analytics",
    icon: "solid,magnifying-glass-chart",
  },
  congestion: {
    label: "Congestion Analytics",
    icon: "solid,chart-network",
  },
  macro: {
    label: "Macro Analytics",
    icon: "solid,chart-mixed",
  },
  demurrage: {
    label: "Demurrage Analytics",
    icon: "solid,file-invoice-dollar",
  },
  trip: {
    label: "Trip Analytics",
    icon: "solid,arrows-spin",
  },
  pricing: {
    label: "Pricing Analytics",
    icon: "solid,chart-mixed-up-circle-dollar",
  },
  custom: {
    label: "Custom Analytics",
    icon: "solid,toolbox",
  },
  unit: {
    label: "Active Shuttles",
    icon: "solid,circle-nodes",
  },
  sustainability: {
    label: "Sustainability Report",
    icon: "solid,seedling",
  },
};

export default tableauReportTypes;
