<script setup lang="ts">
import { ref } from "vue";
import { PatternItem } from "@telegraphio/papi-client";
import { RUDDERSTACK_EVENTS } from "@/lib/rudderstack";
import useTrackerStore from "@/stores/trackers";
import DotMenuButton from "@/components/common/buttons/DotMenuButton.vue";
import ListItemCommonData from "@/components/waybilling/ListItemCommonData.vue";
import AppPopover from "@/components/app/AppPopover.vue";
import LayoutBox from "@/components/layout/LayoutBox.vue";

const props = defineProps<{ pattern: PatternItem }>();

const emit = defineEmits<{
  delete: [patternId: string];
  duplicate: [patternId: string];
}>();

const trackers = useTrackerStore();

const confirmDelete = ref(false);
const rsData = {
  pattern_id: props.pattern.patternId as string,
};
</script>

<template>
  <tr>
    <ListItemCommonData class="border-gray min-w-64 max-w-72 whitespace-break-spaces border py-4 pl-6 pr-2">
      <router-link
        class="font-medium text-blue"
        :to="{ name: 'editPattern', params: { patternId: pattern.patternId } }"
        @click="trackers.logRudderstackEvent(RUDDERSTACK_EVENTS.WAYBILLING_PATTERN_LIST_EDIT_PATTERN, rsData)"
      >
        {{ pattern.label }}
      </router-link>
    </ListItemCommonData>
    <ListItemCommonData class="border-gray w-56 whitespace-break-spaces border py-4 pl-6 font-semibold leading-none">
      {{ pattern.createdBy }}
    </ListItemCommonData>
    <ListItemCommonData>
      {{ pattern.lastUsedDate ? $date("MMMM DD, YYYY", pattern.lastUsedDate) : "" }}
    </ListItemCommonData>
    <ListItemCommonData class="border-gray w-10 border pl-4">
      <AppPopover>
        <template #popoverButton>
          <DotMenuButton class="m-auto" :is-large="false" />
        </template>
        <template #default="{ close }">
          <LayoutBox class="mr-2 flex w-44 flex-col items-center">
            <button
              class="w-full rounded-tl-md rounded-tr-md border-b py-2 text-center font-semibold text-black hover:bg-gray-200"
              @click="
                trackers.logRudderstackEvent(RUDDERSTACK_EVENTS.WAYBILLING_PATTERN_LIST_DUPLICATE_PATTERN, rsData);
                emit('duplicate', pattern.patternId || '');
                close();
              "
            >
              Duplicate
            </button>
            <router-link class="w-full" :to="{ name: 'editPattern', params: { patternId: pattern.patternId } }">
              <button
                class="w-full border-b py-2 text-center font-semibold text-black hover:bg-gray-200"
                @click="trackers.logRudderstackEvent(RUDDERSTACK_EVENTS.WAYBILLING_PATTERN_LIST_EDIT_PATTERN, rsData)"
              >
                Edit
              </button>
            </router-link>
            <router-link class="w-full" :to="{ name: 'createTender', params: { patternId: pattern.patternId } }">
              <button
                class="w-full border-b py-2 text-center font-semibold text-black hover:bg-gray-200"
                @click="trackers.logRudderstackEvent(RUDDERSTACK_EVENTS.WAYBILLING_PATTERN_LIST_BOOK_TENDER, rsData)"
              >
                Book
              </button>
            </router-link>
            <button
              class="w-full p-2 text-center text-red hover:bg-red hover:text-white"
              :class="{ 'border-b': confirmDelete === true, 'rounded-bl-md rounded-br-md': confirmDelete === false }"
              @click="confirmDelete = true"
            >
              <span>
                <i class="fa-solid fa-trash-can mr-2" />
              </span>
              Delete Pattern
            </button>
            <button
              v-show="confirmDelete === true"
              class="w-full rounded-bl-md rounded-br-md bg-red p-2 text-center text-white hover:bg-red-600 hover:text-white"
              @click="
                trackers.logRudderstackEvent(RUDDERSTACK_EVENTS.WAYBILLING_PATTERN_LIST_DELETE_PATTERN, rsData);
                emit('delete', pattern.patternId || '');
                confirmDelete = false;
                close();
              "
            >
              Confirm Delete
            </button>
          </LayoutBox>
        </template>
      </AppPopover>
    </ListItemCommonData>
  </tr>
</template>
