<script setup lang="ts">
import { reset } from "@formkit/core";
import { setErrors } from "@formkit/vue";
import { useRouter } from "vue-router";
import { useFetch } from "@/composables/fetch";
import { useUserStore } from "@/stores/user";
import { deSpacinator } from "@/utils/filters";
import useTrackerStore from "@/stores/trackers";
import { RUDDERSTACK_EVENTS } from "@/lib/rudderstack";
import { ref, watch } from "vue";
import type { AppSearchEvent } from "@/types/rudderstack";

const props = defineProps<{
  showSearch: boolean;
}>();

const trackers = useTrackerStore();
const router = useRouter();
const initialPlaceholderText = "ABCD001234";
const placeholder = ref(initialPlaceholderText);

async function goToEquipment(formData: Record<"equipment_id", string>) {
  const rsData: AppSearchEvent = {
    ...formData,
    success: false,
  };

  if (!formData.equipment_id) {
    rsData.success = false;
    rsData.error = "No asset ID provided";
    trackers.logRudderstackEvent(RUDDERSTACK_EVENTS.APP_SEARCH_EXECUTE, rsData);
    reset("global-asset-search");
    return undefined;
  }
  const request = useFetch();
  const userStore = useUserStore();

  formData.equipment_id = deSpacinator(formData.equipment_id);

  const response = await request.post(`/waybills/${userStore.companyId}/search`, {
    body: formData,
  });

  if (!response.ok) {
    console.error("Error searching for asset", response.statusText);
    rsData.success = false;
    rsData.error = "Unable to find asset";
    placeholder.value = "Unable to find asset";
    trackers.logRudderstackEvent(RUDDERSTACK_EVENTS.APP_SEARCH_EXECUTE, rsData);
    setErrors("global-asset-search", { equipment_id: "" });
    setTimeout(() => {
      reset("global-asset-search");
    }, 1000);
    return undefined;
  }
  const data = await response.json();
  rsData.success = true;
  placeholder.value = initialPlaceholderText;
  trackers.logRudderstackEvent(RUDDERSTACK_EVENTS.APP_SEARCH_EXECUTE, rsData);
  reset("global-asset-search");
  const routeData = router.resolve({
    name: "assetDetails",
    params: { companyId: userStore.companyId, waybillId: data.waybill_id },
  });
  window.open(routeData.href, "_blank");
}

function startResetTimeout() {
  placeholder.value = "Enter complete asset ID";
  setTimeout(() => {
    reset("global-asset-search");
  }, 1100);
}

watch(
  () => props.showSearch,
  () => {
    if (props.showSearch === false) {
      placeholder.value = initialPlaceholderText;
      reset("global-asset-search");
    }
  },
);
</script>

<template>
  <div class="flex w-full" :class="{ 'px-3': showSearch }" title="Asset ID lookup tool">
    <i v-if="!showSearch" class="fa-solid fa-magnifying-glass fa-lg mb-2 p-6 pb-3 text-white-ice" />
    <FormKit
      v-if="showSearch"
      type="form"
      :actions="false"
      :incomplete-message="false"
      @submit="goToEquipment"
      @submit-invalid="startResetTimeout"
    >
      <FormKit
        id="global-asset-search"
        ref="globalAssetSearch"
        name="equipment_id"
        :placeholder="placeholder"
        prefix-icon="search"
        validation="length:8,11"
        validation-visibility="submit"
        help="Enter one complete asset ID for search"
        :classes="{
          outer: 'flex flex-col-reverse mb-2',
          help: 'pb-1 text-white-ice z-20 text-xs text-nowrap',
          input: 'placeholder:italic group-data-[errors]:bg-red-200 group-data-[invalid]:bg-red-200 bg-white',
          messages: 'hidden',
        }"
        type="text"
      ></FormKit>
    </FormKit>
  </div>
</template>
