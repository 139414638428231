import { RouteRecordRaw } from "vue-router";
import CustomerSupportDashboard from "@/pages/customerSupport/CustomerSupportDashboard.vue";
import TicketPanel from "@/components/customerSupport/TicketPanel.vue";
import NewTicketForm from "@/components/customerSupport/NewTicketForm.vue";

const customerSupportRoutes: RouteRecordRaw = {
  name: "customerSupport",
  path: "/customer-support/:customerId/tickets",
  component: CustomerSupportDashboard,
  props: true,
  meta: { authenticated: true, title: "Telegraph - Customer Support" },
  children: [
    {
      name: "newTicket",
      path: "new",
      component: NewTicketForm,
      meta: { authenticated: true },
      props: true,
    },
    {
      name: "ticket",
      path: ":ticketId",
      component: TicketPanel,
      meta: { authenticated: true },
    },
  ],
};

export default customerSupportRoutes;
