import { BAPIBaseAsset, BaseAsset } from "@/bapi-client/types/assets/baseAsset";
import { EquipmentStatus } from "@/bapi-client/types/assets/equipmentStatus";
import { makeETA } from "@/bapi-client/factories";

export function makeBaseAsset<T extends BAPIBaseAsset>(response: T): BaseAsset {
  return {
    equipmentId: response.equipment_id,
    waybillId: response.waybill_id,
    status: response.status as EquipmentStatus,
    statusCode: response.status_code,
    loadEmptyStatus: response.load_empty_status as "L" | "E",
    billOfLadingNumber: response.bill_of_lading_number,
    intermodalShipment: response.intermodal_shipment,
    sealNumber: response.seal_number,
    imdlPickupNumber: response.imdl_pickup_number,
    poNumber: response.po_number,
    imdlBookingNumber: response.imdl_booking_number,
    equipmentHeight: response.equipment_height,
    equipmentLength: response.equipment_length,
    equipmentWeight: response.equipment_weight,
    tareWeight: response.tare_weight,
    commodity: response.commodity,
    commodityDescription: response.commodity_description,
    currentLocation: response.current_location,
    lastEventText: response.last_event_text,
    lastSightingDate: response.last_sighting_date,
    originLocation: response.origin_location,
    destinationLocation: response.destination_location,
    daysSinceLastSighting: response.days_since_last_sighting !== "" ? Number(response.days_since_last_sighting) : "",
    exceptions: response.exceptions,
    railETA: makeETA(response.rail_eta),
    teleETA: makeETA(response.tele_eta),
    get teleETADate() {
      return this.teleETA?.date ?? "";
    },
    get railETADate() {
      return this.railETA?.date ?? "";
    },
  };
}
