import { ref, computed, Ref, toRef } from "vue";
import type { Operand } from "@/types";
import { toAlphanumeric } from "@/utils/text";

export function useFiltered<T extends Record<string, unknown>>(items: T[] | Ref<T[]> | (() => T[])) {
  const data = toRef(items);
  const filters: Ref<Record<string, { operand: Operand; value: string | number | string[] }>> = ref({});

  function setFilter(key: string, value: string | number | string[], operand?: Operand) {
    const out = `${key},${operand ?? filters.value[key].operand},${value}`;

    if (!filters.value[key]) {
      filters.value[key] = {
        value,
        operand: operand ?? "equals",
      };
      return out;
    }

    filters.value[key].value = value;
    filters.value[key].operand = operand ?? filters.value[key].operand;
    return out;
  }

  const filtered = computed(() => {
    if (!Object.keys(filters.value).length) {
      return data.value;
    }

    const out = [];
    for (const item of data.value) {
      const passes: Record<string, boolean> = {};
      for (const filterKey in filters.value) {
        passes[filterKey] = false;
        if (!(filterKey in item)) {
          console.warn(`Unable to filter record by ${filterKey}. Object does not have key "${filterKey}".`);
          continue;
        }

        // Get these into compatible types
        const operand = filters.value[filterKey].operand;
        let itemValue: unknown;
        let filterValue;

        if (!Array.isArray(item[filterKey]) && !Array.isArray(filters.value[filterKey].value)) {
          if (typeof item[filterKey] === "number" || typeof filters.value[filterKey].value === "number") {
            const itemIsNaN = isNaN(Number(item[filterKey]));
            const valueIsNaN = isNaN(Number(filters.value[filterKey].value));
            if (itemIsNaN || valueIsNaN) {
              console.warn("Attempted numeric comparison, but one or more provided values was NaN");
              passes[filterKey] = false;
              continue;
            } else {
              itemValue = Number(item[filterKey]);
              filterValue = Number(filters.value[filterKey].value);
            }
          } else {
            itemValue = item[filterKey];
            filterValue = filters.value[filterKey].value;
          }
        } else {
          itemValue = item[filterKey];
          filterValue = filters.value[filterKey].value;
        }

        switch (operand) {
          case "equals":
            passes[filterKey] = !filterValue ? true : itemValue === filterValue;
            break;
          case "less_than":
            passes[filterKey] =
              typeof itemValue === "number" && typeof filterValue === "number"
                ? itemValue < filterValue
                : ("" + itemValue).localeCompare(filterValue.toString()) < 0;
            break;
          case "less_than_or_equals":
            passes[filterKey] =
              typeof itemValue === "number" && typeof filterValue === "number"
                ? itemValue <= filterValue
                : ("" + itemValue).localeCompare(filterValue.toString()) <= 0;
            break;
          case "greater_than":
            passes[filterKey] =
              typeof itemValue === "number" && typeof filterValue === "number"
                ? itemValue > filterValue
                : ("" + itemValue).localeCompare(filterValue.toString()) > 0;
            break;
          case "greater_than_or_equals":
            passes[filterKey] =
              typeof itemValue === "number" && typeof filterValue === "number"
                ? itemValue >= filterValue
                : ("" + itemValue).localeCompare(filterValue.toString()) >= 0;
            break;
          case "contains":
            // Two cases: item[filterKey] is an Array and value is an array OR
            // item[filterKey] is an array and value is a string
            if (Array.isArray(itemValue) && !filterValue) {
              passes[filterKey] = true;
              break;
            }

            if (Array.isArray(filterValue) && !filterValue.length) {
              passes[filterKey] = true;
              break;
            }

            if (Array.isArray(filterValue) && !Array.isArray(itemValue)) {
              passes[filterKey] = filterValue
                .map((val) => toAlphanumeric(val))
                .includes(toAlphanumeric("" + itemValue));
              break;
            }

            passes[filterKey] = Array.isArray(filterValue)
              ? filterValue
                  .map((val) => toAlphanumeric(val))
                  .some((val) => (itemValue as string[]).map((val) => toAlphanumeric(val)).includes(val))
              : !!filterValue && (itemValue as string[]).includes(filterValue as string);
            break;
        }
      }

      if (Object.values(passes).every((pass) => pass === true)) {
        out.push(item);
      }
    }
    return out;
  });

  function resetFilters() {
    for (const key in filters.value) {
      switch (typeof filters.value[key].value) {
        case "string":
          filters.value[key].value = "";
          break;
        case "number":
          filters.value[key].value = 0;
          break;
        case "object":
          filters.value[key].value = [];
          break;
      }
    }
  }

  const hasFilters = computed(() => {
    return Object.values(filters.value).some((filter) => {
      if (Array.isArray(filter.value)) return filter.value.length > 0;
      return !!filter.value;
    });
  });

  return { filtered, filters, resetFilters, hasFilters, setFilter };
}
