<script setup lang="ts">
import { computed } from "vue";
import { formatEDILabel } from "@/utils/edi";

const props = defineProps<{
  label: string;
  isDirty: boolean;
  isValid: boolean;
  seen: boolean;
  showStem: boolean;
  showRawLabel: boolean;
}>();

const customLabels: Record<string, string> = {
  extended_reference_information_N9: "References",
  transaction_set_line_number_LX_loop: "Commodities",
  equipment_details_N7_loop: "Equipment",
  party_identification_N1_loop: "Parties",
  protective_service_instructions_PS: "Protective Service",
  empty_car_disposition_pended_destination_consignee_E1_loop: "Empty Disposition",
};

const formattedLabel = computed(() => {
  if (props.showRawLabel) {
    return props.label;
  }

  if (customLabels[props.label]) {
    return customLabels[props.label];
  }

  return formatEDILabel(props.label);
});
</script>

<template>
  <div v-if="showStem" class="ml-[17px] h-3 w-0.5" :class="[seen ? 'bg-blue-sky-500' : 'bg-gray-300']" />
  <div class="flex items-center">
    <div
      class="mr-2 flex h-9 w-9 shrink-0 items-center justify-center rounded-full"
      :class="[seen ? 'bg-blue-sky-500' : 'bg-gray-300']"
    >
      <div
        v-if="isValid"
        data-testid="progress-valid"
        class="flex h-4 w-4 items-center justify-center rounded-full bg-green-atomic-600"
      >
        <i class="fa-solid fa-check text-xs text-blue-sky-500" />
      </div>
      <div
        v-else-if="isDirty"
        data-testid="progress-invalid"
        class="flex h-4 w-4 items-center justify-center rounded-full bg-red-600"
      >
        <i class="fa-solid fa-xmark text-xs" :class="[seen ? 'text-blue-sky-500' : 'text-gray-300']" />
      </div>
      <div v-else class="flex h-4 w-4 items-center justify-center rounded-full bg-white">
        <i class="fa-solid fa-xmark text-xs text-gray-300" />
      </div>
    </div>
    <p class="text-xs">{{ formattedLabel }}</p>
  </div>
</template>
