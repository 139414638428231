<script setup lang="ts">
import useNotificationStore from "@/stores/notifications";

const notifier = useNotificationStore();
</script>

<template>
  <div v-if="notifier.platformAlertEnabled" aria-role="alert" class="w-full p-6">
    <div
      :class="{
        'border-l-orange bg-orange-50': notifier.platformAlert?.status === 'warning',
        'border-l-green-olive bg-green-olive-50': notifier.platformAlert?.status === 'success',
      }"
      class="w-full border-l-8 p-6"
    >
      <div class="mb-3 flex items-center justify-between">
        <h1 class="text-base font-bold">{{ notifier.platformAlert?.title }}</h1>
        <a href="#0" class="text-xl text-gray-900" @click.prevent="notifier.dismissPlatformAlert">
          <i class="fa-solid fa-xmark"></i>
        </a>
      </div>
      <p>{{ notifier.platformAlert?.message }}</p>
    </div>
  </div>
</template>
