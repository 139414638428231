<script setup lang="ts">
import { useRouter, useRoute } from "vue-router";
import { computed } from "vue";
import { SidebarMenuGroup } from "@/components/menus";
import { useAnalyticsStore } from "@/stores/analytics";
import { useUserStore } from "@/stores/user";
import tableauReportTypes from "@/models/tableauReportTypes";

interface Props {
  isOpen: boolean;
}

defineProps<Props>();

const router = useRouter();
const route = useRoute();
const analyticsStore = useAnalyticsStore();
const userStore = useUserStore();

const links = computed(() => {
  return analyticsStore.views
    .map((view) => {
      return {
        to: router.resolve({
          name: "advancedAnalytics",
          params: { companyId: userStore.companyId },
          query: {
            report: encodeURIComponent(view.url),
            report_type: view.report_type,
          },
        }),
        label: tableauReportTypes[view.report_type]?.label ?? "Analytics Report",
        current: view.report_type === analyticsStore.selectedView?.report_type,
        icon: tableauReportTypes[view.report_type]?.icon ?? "solid,square-poll-vertical",
      };
    })
    .sort((a, b) => {
      return a.label.localeCompare(b.label);
    });
});
</script>

<template>
  <SidebarMenuGroup
    :is-open="isOpen"
    label="Analytics"
    icon="solid,square-poll-vertical"
    :links="links"
    :current="route.path.includes('/analytics')"
  />
</template>
