<script setup lang="ts">
import { useRouteQuery } from "@vueuse/router";
import dayjs from "dayjs";
import { CSTicket } from "@/bapi-client/types/customer-support";
import { TgTable, TgPagination } from "@/components/common";
import type { TableColumn } from "@/types";
import TicketTableRow from "./TicketTableRow.vue";

const sortColumn = useRouteQuery<string>("sort");
const sortDirection = useRouteQuery<"ASC" | "DESC">("direction");
const pageSize = useRouteQuery("page_size", "25", { transform: Number });
const pageNumber = useRouteQuery("page_number", "1", { transform: Number });
const props = defineProps<{ customerId: string }>();

const tickets: CSTicket[] = [];

for (let i = 0; i < 250; ++i) {
  tickets.push({
    id: `TG${i < 10 ? "000" + i : "00" + i}`,
    name: "New Ticket",
    status: "In-Progress",
    equipmentIds: [`EMHU ${i < 10 ? "11122" + i : "1112" + i}`],
    reportingGroups: [`Group ${i}`],
    railroads: ["TGRR"],
    loadedEmpty: i % 2 === 0 ? "L" : "E",
    tags: ["Other"],
    created: dayjs().subtract(3, "days").toISOString(),
    updated: dayjs().subtract(2, "hours").toISOString(),
    assignees: ["TG Admin"],
    customerId: props.customerId,
    description: "This is a ticket.",
    priority: ["1", "2", "3"][Math.floor(Math.random() * 3)],
    closed: "",
    followers: [],
  });
}

const columns: TableColumn[] = [
  { label: "", value: "priority" },
  { label: "Ticket", value: "ticket_number" },
  { label: "Ticket Name", value: "ticket_name" },
  { label: "Reporting Group", value: "reporting_group" },
  { label: "Asset(s)", value: "equipment_id" },
  { label: "RR", value: "reporting_scac" },
  { label: "L/E", value: "load_empty_status" },
  { label: "Tag(s)", value: "tags" },
  { label: "Created", value: "created_at" },
  { label: "Last Action", value: "updated_at" },
  { label: "Status", value: "ticket_status" },
  { label: "Assignee", value: "assignee" },
];

function setSort(column: string) {
  if (column === sortColumn.value) {
    sortColumn.value = column;
    sortDirection.value = sortDirection.value === "ASC" ? "DESC" : "ASC";
    return sortDirection.value;
  }
  sortColumn.value = column;
  sortDirection.value = "ASC";
}
</script>

<template>
  <section class="rounded-md border">
    <TgPagination
      v-model:page-size="pageSize"
      v-model:current-page="pageNumber"
      :total="250"
      :last-page="1"
      with-download
      with-totals
      with-refresh
      with-page-size
      class="rounded-tl-md rounded-tr-md bg-white py-6 pl-10 pr-6"
    />
    <TgTable
      class="overflow-x-auto"
      :columns="columns"
      :sort-column="sortColumn"
      :sort-direction="sortDirection"
      @sort="setSort($event as string)"
    >
      <TicketTableRow v-for="ticket in tickets" :key="ticket.id" :ticket="ticket" :customer-id="customerId" />
    </TgTable>
  </section>
</template>
