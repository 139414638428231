import { BAPI_COMMANDS, BAPICommand } from "./types/commands";
import { submitInteledeskForm, templateHandlers, uploadS3File } from "./handlers";
import { getStccs } from "./handlers/stccHandler";
import { abort as fetchAbort } from "./fetch";
import {
  adminCreateCustomer,
  adminDeleteCustomer,
  adminGetCustomer,
  adminUpdateCustomer,
  adminAddCustomerEquipment,
  adminDeleteCustomerEquipment,
  adminGetCustomers,
} from "./handlers/internal-tools/customers";
import { getOrigins, getDestinations, getAllLocations } from "./handlers/locationHandlers";
import { getWaybillDetails, getWaybillDetailsActivityFeed } from "./handlers/waybills";
import { getMe, getProfilePictureUpload, loginUser, logoutUser, uploadUserProfilePicture } from "./handlers/users";
import { autocompleteUsers } from "./handlers/autocomplete/users";
import { autocompleteAssets } from "./handlers/autocomplete/assets";
import { getAssetHistory } from "./handlers/assetHandler";

const BAPIHandlers = {
  [BAPI_COMMANDS.GET_REPORT_TEMPLATES]: templateHandlers.getReportTemplates,
  [BAPI_COMMANDS.GET_REPORT_TEMPLATE]: templateHandlers.getReportTemplate,
  [BAPI_COMMANDS.DELETE_REPORT_TEMPLATE]: templateHandlers.deleteReportTemplate,
  [BAPI_COMMANDS.CREATE_REPORT_TEMPLATE]: templateHandlers.createReportTemplate,
  [BAPI_COMMANDS.UPDATE_REPORT_TEMPLATE]: templateHandlers.updateReportTemplate,
  [BAPI_COMMANDS.GET_REPORT_TEMPLATE_FIELDS]: templateHandlers.getReportTemplateFields,
  [BAPI_COMMANDS.GET_STCCS]: getStccs,
  [BAPI_COMMANDS.ADMIN_GET_CUSTOMER]: adminGetCustomer,
  [BAPI_COMMANDS.ADMIN_GET_CUSTOMERS]: adminGetCustomers,
  [BAPI_COMMANDS.ADMIN_CREATE_CUSTOMER]: adminCreateCustomer,
  [BAPI_COMMANDS.ADMIN_UPDATE_CUSTOMER]: adminUpdateCustomer,
  [BAPI_COMMANDS.ADMIN_DELETE_CUSTOMER]: adminDeleteCustomer,
  [BAPI_COMMANDS.ADMIN_CUSTOMER_EQUIPMENT_ADD]: adminAddCustomerEquipment,
  [BAPI_COMMANDS.ADMIN_CUSTOMER_EQUIPMENT_DELETE]: adminDeleteCustomerEquipment,
  [BAPI_COMMANDS.GET_ORIGINS]: getOrigins,
  [BAPI_COMMANDS.GET_DESTINATIONS]: getDestinations,
  [BAPI_COMMANDS.GET_ALL_LOCATIONS]: getAllLocations,
  [BAPI_COMMANDS.WAYBILL_GET_ACTIVITY_FEED]: getWaybillDetailsActivityFeed,
  [BAPI_COMMANDS.WAYBILL_GET_WAYBILL_DETAILS]: getWaybillDetails,
  [BAPI_COMMANDS.GET_ASSET_HISTORY]: getAssetHistory,
  [BAPI_COMMANDS.USER_GET_ME]: getMe,
  [BAPI_COMMANDS.USER_LOGIN]: loginUser,
  [BAPI_COMMANDS.USER_LOGOUT]: logoutUser,
  [BAPI_COMMANDS.USER_GET_PROFILE_PICTURE_UPLOAD_URL]: getProfilePictureUpload,
  [BAPI_COMMANDS.USER_UPLOAD_PROFILE_PICTURE]: uploadUserProfilePicture,
  [BAPI_COMMANDS.S3_UPLOAD_FILE]: uploadS3File,
  [BAPI_COMMANDS.AUTOCOMPLETE_USERS]: autocompleteUsers,
  [BAPI_COMMANDS.AUTOCOMPLETE_ASSETS]: autocompleteAssets,
  [BAPI_COMMANDS.INTELEDESK_SUBMIT]: submitInteledeskForm,
} as const;

type HParam = { [K in BAPICommand]: Parameters<(typeof BAPIHandlers)[K]> };
type HReturn = { [K in BAPICommand]: ReturnType<(typeof BAPIHandlers)[K]> };
const mappedHandlers: { [K in BAPICommand]: (...args: HParam[K]) => HReturn[K] } = BAPIHandlers;

/** Provides access to BAPI commands by provided name and returns appropriate Result for the command
 *
 * @param name
 * Calls BAPI handler registered at provided name
 *
 * @param opts
 * Expected options and request body for returned handler
 */
export function useBapi<T extends keyof typeof BAPIHandlers>(name: T, ...args: HParam[T]) {
  const handler = mappedHandlers[name];
  return handler(...args);
}

export const abort = fetchAbort;
