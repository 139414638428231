<script setup lang="ts">
import { ref, computed } from "vue";
import SidebarCustomerMenu from "./SidebarCustomerMenu.vue";
import SidebarUserMenu from "./SidebarUserMenu.vue";
import AppAssetSearch from "@/components/app/AppAssetSearch.vue";
import useFeatureStore from "@/stores/features";
import InTeleDeskForm from "@/components/InTeleDeskForm.vue";

defineEmits<{ (event: "close-sidebar"): void }>();
const menu = ref<HTMLElement | null>(null);
const isOpen = ref(false);
const isFormOpen = ref(false);
const featureStore = useFeatureStore();

const bgClasses = computed(() => {
  return import.meta.env.PROD
    ? "bg-gradient-to-r from-blue-midnight to-blue-midnight-400"
    : "bg-gradient-to-b from-purple-900 to-purple-950";
});
// We want to trigger a click _somewhere_ so the nested links will close themselves.
function closeMenu(): void {
  // Do not close menu if form popover is open
  if (isFormOpen.value) {
    return;
  }

  const el = menu.value;
  if (el !== null) {
    el.click();
  }
  isOpen.value = false;
}

function handleCloseForm(): void {
  isFormOpen.value = false;
  isOpen.value = false;
}

function handleOpenForm(open: boolean): void {
  isFormOpen.value = open;

  if (!open) {
    isOpen.value = false;
  }
}
</script>

<template>
  <!-- Static sidebar for desktop -->
  <div
    ref="menu"
    data-test="sidebar-menu"
    data-testid="sidebar-menu"
    class="z-50 hidden transition-all duration-200 ease-out xl:fixed xl:inset-y-0 xl:left-0 xl:flex xl:flex-col"
    :class="[isOpen ? 'w-64' : 'w-16']"
    @mouseenter="isOpen = true"
    @mouseleave="closeMenu"
  >
    <!-- Sidebar component, swap this element with another sidebar if you like -->
    <div class="from-r flex min-h-0 flex-1 flex-col" :class="bgClasses">
      <div class="flex flex-1 flex-col overflow-y-auto overflow-x-hidden pb-4 pt-5">
        <div class="flex w-full flex-shrink-0 items-center px-4">
          <img class="h-auto w-12 shrink-0" src="/src/assets/tg_logo_outline_cropped.png" alt="Telegraph logo" />
          <div class="grow">
            <SidebarCustomerMenu v-if="isOpen" />
          </div>
        </div>
        <nav class="mt-5 flex-1 space-y-1">
          <slot name="navigation" :is-open="isOpen" />
        </nav>
      </div>
      <InTeleDeskForm
        v-if="featureStore.features.hasInteledeskForm && isOpen"
        @close="handleCloseForm"
        @open="handleOpenForm($event)"
      />
      <AppAssetSearch v-if="featureStore.features.hasVisibilityFeatures" :show-search="isOpen" />
    </div>
    <SidebarUserMenu :sidebar-open="isOpen" />
  </div>
</template>
