<script setup lang="ts">
import dayjs from "dayjs";
import { computed } from "vue";
import { useRouter } from "vue-router";
import { useUserStore } from "@/stores/user";
import { TgButton, OutlineButton } from "@/components/common";
import { AutocompleteUsers } from "@/components/autocomplete";
import TicketPanel from "@/components/customerSupport/TicketPanel.vue";
import PriorityDropdown from "@/components/customerSupport/PriorityDropdown.vue";
import TagsDropdown from "@/components/customerSupport/TagsDropdown.vue";
import AssetFieldsRepeater from "@/components/customerSupport/AssetFieldsRepeater.vue";
const router = useRouter();
const userStore = useUserStore();

interface Props {
  customerId: string;
}

const rowClasses = "grid grid-cols-2 gap-4 pb-4";
const labelClasses = "text-sm !text-gray-500 font-semibold !mb-0 self-center";

const userName = computed(() => {
  return userStore.user?.firstName + " " + userStore.user?.lastName;
});

defineProps<Props>();
</script>

<template>
  <TicketPanel :customer-id="customerId">
    <div class="min-w-[600px]">
      <FormKit type="form" :actions="false">
        <div class="border-b p-4">
          <FormKit
            type="text"
            name="ticketName"
            data-testid="ticket-name-input"
            label="Ticket Name"
            :classes="{
              label: 'text-sm text-gray-500 font-semibold',
              inner: 'shadow-inner rounded-none',
            }"
          />
        </div>
        <div class="border-b p-4">
          <div :class="rowClasses">
            <div class="flex">
              <PriorityDropdown :label-classes="labelClasses" />
            </div>
            <div class="flex items-center text-sm">
              <span :class="labelClasses" class="w-1/4">Creator</span>
              {{ userName }}
              <FormKit type="hidden" name="creator" data-testid="creator-input" :value="userName" />
            </div>
          </div>

          <div :class="rowClasses">
            <div class="flex">
              <FormKit
                type="dropdown"
                data-testid="le-input"
                name="loadedEmpty"
                label="L/E"
                placeholder="- -"
                :options="[
                  { label: 'Loaded', value: 'loaded' },
                  { label: 'Empty', value: 'empty' },
                ]"
                :classes="{
                  label: labelClasses + ' w-1/4',
                  wrapper: 'flex !flex-row',
                }"
                :selected-icon="null"
              />
            </div>
            <div class="flex items-center text-sm">
              <span :class="labelClasses" class="w-1/4">Ticket #</span>
              <FormKit type="hidden" data-testid="ticket-number-input" name="ticketNo" value="" />
            </div>
          </div>

          <div :class="rowClasses">
            <div class="flex">
              <!-- <FormKit
                type="autocomplete"
                label="Railroad"
                placeholder="Type 3 chars..."
                :classes="{
                  label: labelClasses,
                  wrapper: 'flex !flex-row',
                  inner: '!w-2/3',
                }"
              /> -->
              <span :class="labelClasses">Railroad</span>
            </div>
            <div class="flex items-center">
              <span :class="labelClasses" class="w-1/4">Date</span>
              <span class="text-sm">{{ dayjs().format("MM/DD/YYYY") }}</span>
              <FormKit type="hidden" data-testid="date-input" name="date" :value="dayjs().format('MM/DD/YYYY')" />
            </div>
          </div>

          <div :class="rowClasses">
            <div class="flex">
              <TagsDropdown :label-classes="labelClasses" />
            </div>
            <div class="flex">
              <span :class="labelClasses" class="w-1/4">Assignee</span>
              <AutocompleteUsers name="assignee" :customer-id="customerId" flat />
            </div>
          </div>
        </div>
        <div class="border-b p-4">
          <AssetFieldsRepeater :customer-id="customerId" :label-classes="labelClasses" />
        </div>
        <div class="border-b p-4">
          <FormKit
            type="textarea"
            data-testid="ticket-description-input"
            name="descriptions"
            label="Ticket Description"
            :classes="{
              label: 'text-sm text-gray-500 font-semibold',
              inner: 'shadow-inner',
            }"
          />
        </div>
        <div class="flex justify-between p-4">
          <OutlineButton
            data-testid="ticket-cancel-button"
            color="danger"
            is-small
            @click.prevent="router.push({ name: 'customerSupport' })"
            >Cancel</OutlineButton
          >
          <TgButton data-testid="ticket-create-button" type="submit" is-small>Create</TgButton>
        </div>
      </FormKit>
    </div>
  </TicketPanel>
</template>
